// DON'T IMPORT THIS FILE DIRECTLY, IT SHOULD BE IMPORTED ONLY BY env.ts
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { baseConfig } from "../base/base";
import { auth0 } from "./auth0";
import { firebaseConfig } from "./firebase";
export var devEnv = __assign(__assign({}, baseConfig), { auth0: auth0, firebase: firebaseConfig });
