var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useLazyQuery, useQuery } from "@apollo/client";
import addMonths from "date-fns/addMonths";
import format from "date-fns/format";
import set from "date-fns/set";
import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import { QUERY_GET_DAILY_QUESTION_CALENDAR, QUERY_GET_DAILY_QUESTION_STATS } from "@blueheart/api-spec/src/adminQueries";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getDaysInMonth } from "date-fns";
export var DailyQuestionCalendar = function () {
    var _a, _b, _c;
    var _d = __read(useState(new Date()), 2), date = _d[0], setDate = _d[1];
    var _e = useQuery(QUERY_GET_DAILY_QUESTION_CALENDAR, {
        variables: {
            date: date.toISOString()
        }
    }), data = _e.data, loading = _e.loading;
    var entriesCount = ((_b = (_a = data === null || data === void 0 ? void 0 : data.content) === null || _a === void 0 ? void 0 : _a.dailyQuestionCalendar) === null || _b === void 0 ? void 0 : _b.activities.length) || 0;
    var daysInMonth = getDaysInMonth(date);
    var isValid = entriesCount === daysInMonth;
    return (_jsxs(Container, { children: [_jsx("h1", { children: "Daily Question Calendar" }), _jsx(Row, { children: _jsxs(Col, { children: [_jsx(Button, __assign({ style: { marginRight: 10 }, onClick: function () {
                                setDate(function (currentDate) { return addMonths(currentDate, -1); });
                            } }, { children: "Prev" })), _jsx(Button, __assign({ onClick: function () {
                                setDate(function (currentDate) { return addMonths(currentDate, 1); });
                            } }, { children: "Next" }))] }) }), _jsxs("h2", { children: [format(date, "yyyy-MMM"), !loading && (_jsxs("span", __assign({ style: {
                            paddingLeft: 10,
                            color: isValid ? "green" : "red"
                        } }, { children: ["(", entriesCount, " / ", daysInMonth, ")"] })))] }), loading && "Loading...", data && (_jsx(CalendarView, { calendar: (_c = data === null || data === void 0 ? void 0 : data.content) === null || _c === void 0 ? void 0 : _c.dailyQuestionCalendar, calendarDate: date }))] }));
};
export var CalendarView = function (_a) {
    var calendar = _a.calendar, calendarDate = _a.calendarDate;
    var today = useMemo(function () { return new Date(); }, []);
    if (!calendar)
        return _jsx(_Fragment, { children: "Not found" });
    var dif = differenceInCalendarMonths(today, calendarDate);
    var todayIndex = dif === 0 ? today.getDate() - 1 : dif > 0 ? 100 : -1;
    return (_jsx(Container, { children: calendar.activities.map(function (item, index) {
            var _a;
            if (!item) {
                return (_jsx(Row, { children: _jsx(Col, { children: "MISSING" }) }, "unknown_" + index));
            }
            return (_jsxs(Row, __assign({ style: {
                    borderBottom: "1px solid gray",
                    padding: 3,
                    color: todayIndex === index
                        ? "blue"
                        : todayIndex < index
                            ? "black"
                            : "gray"
                } }, { children: [_jsxs(Col, __assign({ className: "col-md-10" }, { children: [_jsx(Row, { children: _jsxs(Col, __assign({ style: { fontWeight: "bold" } }, { children: [index + 1, ": ", item.title] })) }), _jsx(Row, { children: _jsxs(Col, { children: ["Text: ", item.content] }) }), _jsx(Row, { children: _jsxs(Col, { children: ["Type: ", item.type] }) }), _jsx(Row, { children: _jsxs(Col, { children: ["Tags: ", (_a = item.tags) === null || _a === void 0 ? void 0 : _a.join(", ")] }) })] })), _jsx(Col, __assign({ className: "col-md-2" }, { children: todayIndex >= index && (_jsx(DailyQuestionStats, { calendarDate: calendarDate, date: index + 1 })) }))] }), item.id + "_" + index));
        }) }));
};
var DailyQuestionStats = function (_a) {
    var _b;
    var calendarDate = _a.calendarDate, date = _a.date;
    var day = useMemo(function () {
        return set(calendarDate, { date: date });
    }, [calendarDate, date]);
    var _c = __read(useState(false), 2), showData = _c[0], setShowData = _c[1];
    var _d = __read(useLazyQuery(QUERY_GET_DAILY_QUESTION_STATS, {
        variables: {
            date: day.toISOString()
        }
    }), 2), query = _d[0], _e = _d[1], data = _e.data, loading = _e.loading;
    var stats = (_b = data === null || data === void 0 ? void 0 : data.content) === null || _b === void 0 ? void 0 : _b.dailyQuestionStats;
    if (!showData) {
        return (_jsx(Container, { children: _jsx(Button, __assign({ onClick: function () {
                    setShowData(true);
                    query();
                } }, { children: "Show statistics" })) }));
    }
    if (loading) {
        _jsx(Container, { children: "Loading..." });
    }
    return (_jsxs(_Fragment, { children: [stats && (_jsxs(Container, { children: [_jsxs(Row, { children: ["Asked: ", stats.askedTimes] }), _jsxs(Row, { children: ["Answered: ", stats.answeredTimes] }), _jsxs(Row, { children: ["Completed: ", stats.completedTimes] }), _jsx(Row, { children: _jsx(Link, __assign({ to: "/daily-question-calendar/".concat(day.toISOString(), "/records") }, { children: "View records" })) })] })), !stats && (_jsxs(Container, { children: [_jsx(Row, { children: "Asked: -" }), _jsx(Row, { children: "Answered: -" }), _jsx(Row, { children: "Completed: -" })] }))] }));
};
