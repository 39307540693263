/**
 * Reference for valid Questionnaire Names. Not enforced anywhere so shouldn't be
 * assumed to be exhaustive.
 */
export var QuestionnaireName;
(function (QuestionnaireName) {
    QuestionnaireName["test"] = "test";
    QuestionnaireName["baseline"] = "baseline";
    QuestionnaireName["dynamicAssessment"] = "dynamicAssessment";
    QuestionnaireName["dynamicAssessmentV6"] = "dynamicAssessmentV6";
    QuestionnaireName["dynamicAssessmentV7"] = "dynamicAssessmentV7";
    QuestionnaireName["intercept"] = "intercept";
    QuestionnaireName["onboarding"] = "onboarding";
    QuestionnaireName["superhuman"] = "superhuman";
    QuestionnaireName["personalisation"] = "personalisation";
    QuestionnaireName["selfDevCheckIn"] = "selfDevCheckIn";
    QuestionnaireName["communicationSessionBodyImageBeginnner"] = "communicationsession_bodyimage_beginnner";
    QuestionnaireName["dynamicCheckIn"] = "dynamicCheckIn";
    QuestionnaireName["relationshipHealth"] = "relationshipHealth";
    QuestionnaireName["relationshipQuizV01"] = "relationship-quiz-v01";
})(QuestionnaireName || (QuestionnaireName = {}));
/* Assessments, check-ins, and quizzes are all forms of questionnaires, and use
 * the questionnaire logic in `packages/conversion-graph`.
 *
 * They are typically used for different features in the website and app:
 * - Assessments are used during onboarding, to determine the user's course and
 *   some demographics information
 * - Check-ins are used to determine whether a user has improved
 * - Quizzes can be used instead of assessments for onboarding to onboard a new
 *   user and determine the user's course and some demographics, but can also be
 *   used as content in the app.
 */
export var assessmentQuestionnaireNames = [
    QuestionnaireName.onboarding,
    QuestionnaireName.dynamicAssessment,
    QuestionnaireName.dynamicAssessmentV6,
    QuestionnaireName.dynamicAssessmentV7,
    QuestionnaireName.relationshipHealth
];
export var checkInQuestionnaireNames = [QuestionnaireName.dynamicCheckIn];
export var quizQuestionnaireNames = [QuestionnaireName.relationshipQuizV01];
export var assessmentAndQuizQuestionnaireNames = assessmentQuestionnaireNames.concat(quizQuestionnaireNames);
export var ServerMessageType;
(function (ServerMessageType) {
    ServerMessageType["debugReceive"] = "debug.receive";
    ServerMessageType["debugOpen"] = "debug.open";
    ServerMessageType["partnerLinkSuccessful"] = "partnerLinkSuccessful";
    ServerMessageType["partnerUnlinked"] = "partnerUnlinked";
    ServerMessageType["newChatMessage"] = "message.new";
    ServerMessageType["partnerAnsweredDailyQuestion"] = "message.answered";
    ServerMessageType["progressUpdated"] = "progressUpdated";
    ServerMessageType["showSurvey"] = "showSurvey";
    ServerMessageType["discount"] = "discount";
})(ServerMessageType || (ServerMessageType = {}));
var hasType = function (x) {
    return !!x && typeof x === "object" && "type" in x;
};
export var hasServerMessageType = function (obj) {
    return hasType(obj) &&
        typeof obj.type === "string" &&
        Object.values(ServerMessageType).includes(obj.type);
};
export var PaidAccessSource;
(function (PaidAccessSource) {
    PaidAccessSource["subscription"] = "subscription";
    PaidAccessSource["lifetime"] = "lifetime";
    PaidAccessSource["hasEntitlement"] = "has-entitlement";
    PaidAccessSource["overridden"] = "overridden";
    PaidAccessSource["partner"] = "partner";
    PaidAccessSource["none"] = "none";
})(PaidAccessSource || (PaidAccessSource = {}));
