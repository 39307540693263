var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GQLTodaysTaskStatus } from "@blueheart/api-spec/src/generated/graphql";
import { QUERY_GET_USER_WITH_PARTNER_INFO_AS_ADMIN } from "@blueheart/api-spec/src/adminQueries";
import { Button, Card, Container, Stack, Table } from "react-bootstrap";
import { Header } from "./components/Header";
import { Detail } from "./components/Detail";
export var UserProgress = function () {
    var _a, _b, _c, _d, _e;
    var id = useParams().id;
    var _f = useQuery(QUERY_GET_USER_WITH_PARTNER_INFO_AS_ADMIN, {
        variables: {
            id: id
        }
    }), loading = _f.loading, error = _f.error, data = _f.data, refetch = _f.refetch;
    var _g = __read(useState(false), 2), openToday = _g[0], setOpenToday = _g[1];
    var _h = __read(useState(false), 2), openEvents = _h[0], setOpenEvents = _h[1];
    if (loading) {
        return _jsx("div", { children: "loading" });
    }
    if (error) {
        return (_jsxs("div", { children: [_jsxs("div", { children: ["Error loading: ", "".concat(error)] }), _jsx(Button, __assign({ onClick: function () { return refetch(); } }, { children: "Refetch" }))] }));
    }
    var user = (data === null || data === void 0 ? void 0 : data.getUserAsAdmin.__typename) === "GQLForeignUserAsAdmin"
        ? data.getUserAsAdmin
        : undefined;
    if (!data || !user) {
        return (_jsxs("div", { children: [_jsx("div", { children: "Unexpectedly, did not get exactly one user" }), _jsx(Button, __assign({ onClick: function () { return refetch(); } }, { children: "Refetch" }))] }));
    }
    return (_jsx(Container, __assign({ style: { marginTop: 16, marginBottom: 16 } }, { children: _jsxs(Stack, __assign({ gap: 3 }, { children: [_jsxs(Header, __assign({ title: "User Progress" }, { children: [_jsx(Link, __assign({ to: "/user/".concat(user.id) }, { children: "Details" })), " | Progress"] })), _jsxs(Card, { children: [_jsx(Card.Header, { children: "Stats" }), _jsx(Card.Body, { children: _jsxs("dl", { children: [_jsx(Detail, __assign({ title: "User XP" }, { children: user.totalXP })), _jsx(Detail, __assign({ title: "Current streaks" }, { children: user.streakLength })), _jsx(Detail, __assign({ title: "Streaks record" }, { children: user.streakRecordLength }))] }) })] }), _jsxs(Card, { children: [_jsx(Card.Header, { children: "Courses progress" }), _jsx(Card.Body, { children: _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Course id" }), _jsx("th", { children: "Completed sessions" }), _jsx("th", { children: "Total sessions" }), _jsx("th", { children: "Last activity" })] }) }), _jsx("tbody", { children: (_a = user.progressStats) === null || _a === void 0 ? void 0 : _a.map(function (courseStats, i) { return (_jsxs("tr", { children: [_jsx("td", { children: courseStats.courseId }), _jsx("td", { children: courseStats.sessionsCompleted }), _jsx("td", { children: courseStats.sessionsTotal }), _jsx("td", { children: courseStats.lastActivity })] }, i)); }) })] }) })] }), _jsxs(Card, { children: [_jsx(Card.Header, { children: _jsxs(Stack, __assign({ direction: "horizontal", gap: 3 }, { children: ["Today Assignments", _jsx("span", { className: "ms-auto" }), _jsx(Button, __assign({ onClick: function () {
                                            setOpenToday(function (state) { return !state; });
                                        } }, { children: openToday ? "Hide All -" : "Show All +" }))] })) }), _jsx(Card.Body, { children: _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Type" }), _jsx("th", { children: "Title" }), _jsx("th", { children: "Status" }), _jsx("th", { children: "Solo/Couple" })] }) }), _jsxs("tbody", { children: [(_b = user.todayEntries) === null || _b === void 0 ? void 0 : _b.slice(0, 1).map(function (todayEntry, i) { return (_jsx(TodayEntryRow, { todayEntry: todayEntry }, "base" + i)); }), openToday &&
                                                ((_c = user.todayEntries) === null || _c === void 0 ? void 0 : _c.slice(1).map(function (todayEntry, i) { return (_jsx(TodayEntryRow, { todayEntry: todayEntry }, i)); }))] })] }) })] }), _jsxs(Card, { children: [_jsx(Card.Header, { children: _jsxs(Stack, __assign({ direction: "horizontal", gap: 3 }, { children: ["Content Events", _jsx("span", { className: "ms-auto" }), _jsx(Button, __assign({ onClick: function () {
                                            setOpenEvents(function (state) { return !state; });
                                        } }, { children: openEvents ? "Hide All -" : "Show All +" }))] })) }), _jsx(Card.Body, { children: _jsxs(Table, __assign({ style: { tableLayout: "fixed" } }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Created" }), _jsx("th", { children: "Type" }), _jsx("th", __assign({ style: { width: "50%" } }, { children: "Content" }))] }) }), _jsxs("tbody", { children: [(_d = user.events) === null || _d === void 0 ? void 0 : _d.slice(0, 3).map(function (event, i) { return (_jsx(UserEventRow, { event: event }, "base" + i)); }), openEvents &&
                                                ((_e = user.events) === null || _e === void 0 ? void 0 : _e.slice(3).map(function (event, i) { return _jsx(UserEventRow, { event: event }, i); }))] })] })) })] })] })) })));
};
var TodayEntryRow = function (_a) {
    var todayEntry = _a.todayEntry;
    return (_jsxs(_Fragment, { children: [_jsx("tr", __assign({ style: { backgroundColor: "#EEE" } }, { children: _jsx("td", __assign({ colSpan: 5 }, { children: _jsx("b", { children: todayEntry === null || todayEntry === void 0 ? void 0 : todayEntry.date }) })) })), todayEntry === null || todayEntry === void 0 ? void 0 : todayEntry.tasks.map(function (task) { return (_jsxs("tr", __assign({ title: "Created at \r\n (locale): ".concat(task.createdAtUserTime, " \r\n (server): ").concat(task.createdAtServerTime, " \r\n (data): ").concat(task.rawData) }, { children: [_jsx("td", { children: task.type }), _jsx("td", { children: task.title }), _jsx("td", __assign({ style: {
                            color: task.status === GQLTodaysTaskStatus.Completed
                                ? "green"
                                : "black"
                        } }, { children: task.status })), _jsx("td", { children: task.solo ? "SOLO" : "COUPLE" })] }), task.id)); })] }));
};
var UserEventRow = function (_a) {
    var event = _a.event;
    return (_jsxs("tr", { children: [_jsx("td", { children: event.created }), _jsx("td", { children: event.event_name }), _jsx("td", { children: event.payload })] }));
};
