var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { QUERY_GET_DB_DIAGNOSTICS, QUERY_GET_DIAGNOSTICS } from "@blueheart/api-spec/src/adminQueries";
import React, { useState } from "react";
import { Button, Card, Container, Stack, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Detail } from "./components/Detail";
import { Header } from "./components/Header";
var ItemSection = function (_a) {
    var children = _a.children;
    return _jsx("span", __assign({ style: { marginRight: 30 } }, { children: children }));
};
export var Diagnostics = function () {
    return (_jsx(Header, __assign({ title: "Diagnostics" }, { children: _jsxs(Stack, __assign({ gap: 3 }, { children: [_jsx(Link, __assign({ to: "/diagnostics/database" }, { children: "Database diagnostics" })), _jsx(Link, __assign({ to: "/diagnostics/user" }, { children: "User diagnostics" }))] })) })));
};
var SimpleDataTable = function (_a) {
    var data = _a.data;
    var keys = Object.keys(data[0] || {});
    if (keys.length === 0)
        return _jsx("div", { children: "No data" });
    return (_jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: keys.map(function (key) { return (_jsx("th", { children: key })); }) }) }), _jsx("tbody", { children: data.map(function (item) { return (_jsx("tr", { children: keys.map(function (key) { return (_jsx("td", { children: _jsx("div", __assign({ style: {
                                maxWidth: 180,
                                maxHeight: 120,
                                overflow: "auto"
                            } }, { children: JSON.stringify(item[key]) })) })); }) })); }) })] }));
};
export var DatabaseDiagnostics = function () {
    var _a, _b, _c, _d, _e;
    var _f = useQuery(QUERY_GET_DB_DIAGNOSTICS, {
        variables: {}
    }), loading = _f.loading, error = _f.error, data = _f.data, refetch = _f.refetch;
    if (error)
        return _jsx(_Fragment, { children: "Error" });
    if (loading)
        return _jsx(_Fragment, { children: "Loading" });
    var stats = JSON.parse(((_b = (_a = data === null || data === void 0 ? void 0 : data.getDiagnostics) === null || _a === void 0 ? void 0 : _a.database) === null || _b === void 0 ? void 0 : _b.stats) || "[]");
    var locks = JSON.parse(((_d = (_c = data === null || data === void 0 ? void 0 : data.getDiagnostics) === null || _c === void 0 ? void 0 : _c.database) === null || _d === void 0 ? void 0 : _d.locks) || "[]");
    return (_jsx(Container, __assign({ style: { marginTop: 16, marginBottom: 16 } }, { children: _jsxs(Stack, __assign({ gap: 3 }, { children: [_jsx(Header, __assign({ title: "Database Diagnostics" }, { children: _jsxs(Stack, __assign({ gap: 3 }, { children: [_jsxs(Link, __assign({ to: "/diagnostics" }, { children: ["<", " Back"] })), _jsx("div", { children: _jsx(Button, __assign({ onClick: function () { return refetch(); } }, { children: "Reload" })) })] })) })), _jsx("span", { children: (_e = data === null || data === void 0 ? void 0 : data.getDiagnostics) === null || _e === void 0 ? void 0 : _e.date }), _jsxs("div", { children: [_jsx("h2", { children: "Locks view" }), _jsx(SimpleDataTable, { data: locks })] }), _jsxs("div", { children: [_jsx("h2", { children: "DB Stats" }), _jsx(SimpleDataTable, { data: stats })] })] })) })));
};
export var UserDiagnostics = function () {
    var _a, _b, _c, _d;
    var _e = __read(useState("default"), 2), viewType = _e[0], setViewType = _e[1];
    var _f = useQuery(QUERY_GET_DIAGNOSTICS, {
        variables: {}
    }), loading = _f.loading, error = _f.error, data = _f.data;
    if (error)
        return _jsx(_Fragment, { children: "Error" });
    if (loading)
        return _jsx(_Fragment, { children: "Loading" });
    var usersWithNoAuth = ((_b = (_a = data === null || data === void 0 ? void 0 : data.getDiagnostics) === null || _a === void 0 ? void 0 : _a.users) === null || _b === void 0 ? void 0 : _b.missingAuth) || [];
    var usersWithMissingChat = ((_d = (_c = data === null || data === void 0 ? void 0 : data.getDiagnostics) === null || _c === void 0 ? void 0 : _c.users) === null || _d === void 0 ? void 0 : _d.missingChatSetup) || [];
    return (_jsx(_Fragment, { children: _jsx(Container, __assign({ style: { marginTop: 16, marginBottom: 16 } }, { children: _jsxs(Stack, __assign({ gap: 3 }, { children: [_jsx(Header, __assign({ title: "User Diagnostics" }, { children: _jsxs(Link, __assign({ to: "/diagnostics" }, { children: ["<", " Back"] })) })), _jsx(CollapsableCard, __assign({ title: "Missing firebase accounts", isExpanded: viewType === "user_missing_firebase_auth", onClick: function () { return setViewType("user_missing_firebase_auth"); }, hiddenContent: usersWithNoAuth.map(function (it) { return (_jsxs(Detail, __assign({ title: it.userId }, { children: [_jsx(ItemSection, { children: _jsx(Link, __assign({ to: "/user/".concat(it.userId) }, { children: "details" })) }), _jsxs(ItemSection, { children: ["email: ", it.email || "-"] }), _jsxs(ItemSection, { children: ["0auth: ", it.auth0Id || "-"] })] }), it.userId)); }) }, { children: _jsx(Detail, __assign({ title: "Missing firebase accounts" }, { children: usersWithNoAuth.length })) })), _jsx(CollapsableCard, __assign({ title: "Users missing chat setup", isExpanded: viewType === "user_missing_chat_setup", onClick: function () { return setViewType("user_missing_chat_setup"); }, hiddenContent: usersWithMissingChat.map(function (it) { return (_jsx(Detail, __assign({ title: it.userId }, { children: _jsx(ItemSection, { children: _jsx(Link, __assign({ to: "/user/".concat(it.userId) }, { children: "details" })) }) }), it.userId)); }) }, { children: _jsx(Detail, __assign({ title: "Missing chat tokens" }, { children: usersWithMissingChat.length })) }))] })) })) }));
};
var CollapsableCard = function (_a) {
    var title = _a.title, onClick = _a.onClick, isExpanded = _a.isExpanded, children = _a.children, hiddenContent = _a.hiddenContent;
    return (_jsxs(Card, __assign({ onClick: onClick }, { children: [_jsxs(Card.Header, { children: [title, " ", isExpanded ? "⌄" : "⌃"] }), _jsx(Card.Body, { children: _jsxs("dl", { children: [children, _jsx("hr", {}), isExpanded && hiddenContent] }) })] })));
};
