var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Form, Button, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { MUTATION_RUN_CONTENTFUL_TASK } from "@blueheart/api-spec/src/adminQueries";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Header } from "./components/Header";
var contentfulTask = {
    description: (_jsx("p", { children: "Click this button to download what is currently in contentful into the next version of the app. Please tell Robbie what has changed, so that he can write user-facing release notes when submitting this new version to the App Store" })),
    task: "updateContentful",
    label: "Update contentful and make a release (patch)"
};
var schema = yup.object({
    notes: yup.string().required()
});
var TaskButton = function (_a) {
    var label = _a.label, description = _a.description, displayResult = _a.displayResult;
    var _b = __read(useMutation(MUTATION_RUN_CONTENTFUL_TASK), 2), runTask = _b[0], _c = _b[1], loading = _c.loading, error = _c.error, data = _c.data;
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.runContentfulTask) {
            displayResult(data === null || data === void 0 ? void 0 : data.runContentfulTask);
        }
    }, [data, displayResult]);
    return (_jsx(Formik, __assign({ initialValues: { notes: "" }, onSubmit: function (values, _a) {
            var resetForm = _a.resetForm;
            runTask({ variables: { notes: values.notes } })
                .then(function () { return resetForm(); })
                .catch(console.error);
        }, validationSchema: schema }, { children: function (_a) {
            var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, values = _a.values;
            return (_jsxs(Form, __assign({ onSubmit: handleSubmit }, { children: [_jsx("div", { children: description }), _jsxs(Form.Group, __assign({ className: "mb-3", controlId: "content.updateContentful.notes" }, { children: [_jsx(Form.Label, { children: "Notes for release" }), _jsx(Form.Control, __assign({ as: "textarea", name: "notes", rows: 3, onChange: handleChange, required: true }, { children: values.notes }))] })), _jsx(Button, __assign({ type: "submit", disabled: loading }, { children: label })), error && _jsx(Alert, __assign({ variant: "danger" }, { children: JSON.stringify(error) }))] })));
        } })));
};
export var Content = function () {
    var _a = __read(useState(null), 2), taskResult = _a[0], setTaskResult = _a[1];
    return (_jsxs(_Fragment, { children: [_jsxs(Header, __assign({ title: "Content" }, { children: [_jsx(Row, { children: _jsx(Link, __assign({ to: "/daily-question-calendar" }, { children: "Daily Question Calendar" })) }), _jsx(Row, { children: _jsx(Link, __assign({ to: "/content/personalisation-topics" }, { children: "Recommended Content and Personalisation Topics" })) })] })), _jsx("br", {}), _jsxs(Container, { children: [_jsx(TaskButton, { label: contentfulTask.label, description: contentfulTask.description, displayResult: setTaskResult }), taskResult && _jsx("pre", { children: JSON.stringify(taskResult) })] })] }));
};
