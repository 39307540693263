import { GQLExperimentType } from "@blueheart/api-spec/src/generated/graphql";
export var formatDateString = function (date) {
    return date ? new Date(date).toDateString() : "-";
};
export var filterOnboarding = function (experiments) {
    return experiments.filter(function (exp) { return exp.type === GQLExperimentType.onboarding; });
};
export var filterNonOnboarding = function (experiments) {
    return experiments.filter(function (exp) { return exp.type === GQLExperimentType.other; });
};
export var filterNoStartDate = function (experiments) {
    return experiments.filter(function (exp) { return exp.startDate === undefined || exp.startDate === null; });
};
export var filterNoEndDate = function (experiments) {
    return experiments.filter(function (exp) { return exp.endDate === undefined || exp.endDate === null; });
};
