var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from "react-bootstrap";
export var Detail = function (props) {
    return (_jsxs(Stack, __assign({ direction: "horizontal", style: { alignContent: "center" } }, { children: [_jsxs("dt", __assign({ className: "" }, { children: [props.title, ":"] })), _jsxs("dd", __assign({ style: { margin: 0, marginLeft: 16 } }, { children: [" ", props.children] }))] })));
};
