var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createStructuredSelector } from "reselect";
import { LoadingState } from "@blueheart/utils/src/loadingState";
import Button from "react-bootstrap/Button";
import React from "react";
import { connect } from "react-redux";
import { selectLoginState } from "../store/auth/selectors";
import { authActions } from "../store/auth/actions";
var mapState = createStructuredSelector({
    loginState: selectLoginState
});
var actions = {
    requestLogin: authActions.login.request
};
var AuthBase = function (_a) {
    var loginState = _a.loginState, requestLogin = _a.requestLogin;
    switch (loginState.state) {
        case LoadingState.Cancelled:
        case LoadingState.Failure:
            return (_jsx(Button, __assign({ onClick: function () { return requestLogin({ silent: false }); } }, { children: "Log In" })));
        case LoadingState.Initial:
        case LoadingState.Loading:
            return _jsx("span", { children: "Loading" });
        default:
            return null;
    }
};
export var Auth = connect(mapState, actions)(AuthBase);
