var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { QUERY_GET_ACTIVE_EXPERIMENTS, QUERY_GET_EXPERIMENTS } from "@blueheart/api-spec/src/adminQueries";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Container, Modal, ToggleButton } from "react-bootstrap";
import { ConfirmOperation } from "../ConfirmOperation";
import { AddExperiment } from "./AddExperiment";
import { ExperimentsTable } from "./ExperimentsTable";
import "./experiments.css";
export var Experiments = function () {
    var radioOptions = [
        { name: "All", value: "all" },
        { name: "Active", value: "active" }
    ];
    var _a = __read(useState(false), 2), showAddModal = _a[0], setShowAddModal = _a[1];
    var _b = __read(useState(null), 2), showEditModal = _b[0], setShowEditModal = _b[1];
    var _c = __read(useState(null), 2), confirmModalContents = _c[0], setConfirmModalContents = _c[1];
    var _d = __read(useState([]), 2), data = _d[0], setData = _d[1];
    var _e = __read(useState(false), 2), loading = _e[0], setLoading = _e[1];
    var _f = __read(useState(undefined), 2), error = _f[0], setError = _f[1];
    var _g = __read(useState("all"), 2), selectedOption = _g[0], setSelectedOption = _g[1];
    var resultAll = useQuery(QUERY_GET_EXPERIMENTS, {});
    var resultActive = useQuery(QUERY_GET_ACTIVE_EXPERIMENTS, {});
    useEffect(function () {
        var _a, _b;
        if (selectedOption === "all") {
            setData(((_a = resultAll.data) === null || _a === void 0 ? void 0 : _a.getExperimentsAsAdmin) || []);
            setLoading(resultAll.loading);
            setError(resultAll.error);
        }
        else if (selectedOption === "active") {
            setData(((_b = resultActive.data) === null || _b === void 0 ? void 0 : _b.getActiveExperimentsAsAdmin) || []);
            setLoading(resultActive.loading);
            setError(resultActive.error);
        }
    }, [selectedOption, resultAll, resultActive]);
    return (_jsxs(Container, { children: [error && JSON.stringify(error, null, 2), _jsxs("div", __assign({ className: "actions" }, { children: [_jsx(Modal, __assign({ show: showAddModal, onHide: function () { return setShowAddModal(false); } }, { children: _jsx(AddExperiment, { onAdded: function () { return setShowAddModal(false); } }) })), _jsx(Modal, __assign({ show: !!confirmModalContents, onHide: function () { return setConfirmModalContents(null); } }, { children: confirmModalContents && (_jsx(ConfirmOperation, __assign({}, confirmModalContents))) })), _jsx(Button, __assign({ variant: "primary", onClick: function () { return setShowAddModal(true); } }, { children: "Add experiment" })), _jsx(ButtonGroup, { children: radioOptions.map(function (option, index) { return (_jsx(ToggleButton, __assign({ variant: "outline-primary", type: "radio", name: "radio", value: option.value, checked: selectedOption === option.value, onChange: function (e) { return setSelectedOption(e.currentTarget.value); } }, { children: option.name }), index)); }) })] })), _jsx(ExperimentsTable, { data: data, loading: loading, showEditModal: showEditModal, setShowEditModal: setShowEditModal, setConfirmModalContents: setConfirmModalContents })] }));
};
