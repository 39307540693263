var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_SEARCH_FOR_USERS_AS_ADMIN } from "@blueheart/api-spec/src/adminQueries";
import { Link, useHistory } from "react-router-dom";
import { extractStrings } from "../utils/extractStrings";
export var UsersList = function () {
    var _a;
    var _b = useHistory(), _c = _b.location, search = _c.search, pathname = _c.pathname, push = _b.push;
    var parameters = new URLSearchParams(search);
    var searchTerm = (_a = parameters.get("search-term")) !== null && _a !== void 0 ? _a : "";
    var _d = __read(useState(searchTerm), 2), text = _d[0], setText = _d[1];
    var _e = useQuery(QUERY_SEARCH_FOR_USERS_AS_ADMIN, {
        fetchPolicy: "network-only",
        variables: {
            ids: extractStrings(searchTerm)
        },
        skip: !searchTerm
    }), loading = _e.loading, error = _e.error, data = _e.data;
    return (_jsxs(Container, { children: [_jsx("h2", { children: "User lookup" }), _jsx("p", { children: "Look up users by email address, user id, analytics id, firebase Uid, Stripe Customer Id, idfv, idfa or gps_ad_id. The form can look up multiple at once, one per line." }), _jsx("div", { children: _jsx("textarea", { style: { width: "100%" }, value: text, onChange: function (e) { return setText(e.currentTarget.value); } }) }), _jsx("div", { children: _jsx(Button, __assign({ onClick: function () {
                        var newSearch = new URLSearchParams(search);
                        newSearch.set("search-term", text);
                        push({
                            pathname: pathname,
                            search: newSearch.toString()
                        });
                    }, disabled: loading }, { children: "Search" })) }), error && JSON.stringify(error, null, 2), data && !loading && (_jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Search" }), _jsx("th", { children: "Matches" }), _jsx("th", { children: "Id" }), _jsx("th", { children: "Analytics Id" }), _jsx("th", { children: "Email" }), _jsx("th", { children: "Account created" })] }) }), _jsx("tbody", { children: data.searchForUsersAsAdmin.rows.map(function (_a, idx) {
                            var user = _a.user, searchTerm = _a.searchTerm, kind = _a.kind;
                            return (_jsxs("tr", { children: [_jsx("td", { children: searchTerm }), _jsx("td", { children: kind }), _jsx("td", { children: _jsx(Link, __assign({ to: "/user/".concat(user.id) }, { children: user.id })) }), _jsx("td", { children: user.analyticsId }), _jsx("td", { children: user.email }), _jsx("td", { children: new Date(user.created).toISOString() })] }, idx));
                        }) })] }))] }));
};
