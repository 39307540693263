var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer, getType } from "typesafe-actions";
import { getActionCreatorsArray } from "@blueheart/utils/src/allActions";
import { createLoadingData, LoadingState } from "@blueheart/utils/src/loadingState";
import { authActions } from "./actions";
var initialState = {
    login: { state: LoadingState.Initial },
    logout: { state: LoadingState.Initial }
};
export var authReducer = createReducer(initialState)
    .handleAction(getActionCreatorsArray(authActions.login), function (state, action) { return (__assign(__assign({}, state), { login: createLoadingData(authActions.login, action) })); })
    .handleAction(getActionCreatorsArray(authActions.logout), function (state, action) { return (__assign(__assign({}, state), { logout: createLoadingData(authActions.logout, action), login: action.type === getType(authActions.logout.success)
        ? initialState.login
        : state.login })); });
