/* eslint-disable */
//*****************************************************************************
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// It can be regenerated by running:
// yarn processContent
export var CourseIdEnum;
(function (CourseIdEnum) {
    // legacy gql ids
    CourseIdEnum["lowDesireParent"] = "lowDesireParent";
    CourseIdEnum["highDesireParent"] = "highDesireParent";
    CourseIdEnum["lowDesireCore"] = "lowDesireCore";
    CourseIdEnum["highDesireCore"] = "highDesireCore";
    CourseIdEnum["effectivenessStudy"] = "effectivenessStudy";
    CourseIdEnum["lowDesireSoloThenCouple"] = "lowDesireSoloThenCouple";
    CourseIdEnum["lowDesireCouple"] = "lowDesireCouple";
    CourseIdEnum["sddSolo"] = "sddSolo";
    CourseIdEnum["sddCouples"] = "sddCouples";
    CourseIdEnum["destress_your_relationship_toolkit"] = "destress_your_relationship_toolkit";
    CourseIdEnum["coreSolo"] = "coreSolo";
    CourseIdEnum["coreCouples"] = "coreCouples";
    CourseIdEnum["sensateFocus"] = "sensateFocus";
    // ids as set in contentful
    CourseIdEnum["course_low_desire_core"] = "COURSE-low-desire-core";
    CourseIdEnum["course_high_desire_parent"] = "COURSE-high-desire-parent";
    CourseIdEnum["course_low_desire_parent"] = "COURSE-low-desire-parent";
    CourseIdEnum["course_high_desire_core"] = "COURSE-high-desire-core";
    CourseIdEnum["course_effectiveness_study"] = "COURSE-effectiveness-study";
    CourseIdEnum["course_low_desire_solo_then_couple"] = "COURSE-low-desire-solo-then-couple";
    CourseIdEnum["course_low_desire_couples"] = "COURSE-low-desire-couples";
    CourseIdEnum["course_sdd_couples"] = "COURSE-SDD-COUPLES";
    CourseIdEnum["course_sdd_solo"] = "COURSE-SDD-SOLO";
    CourseIdEnum["course_core_couples"] = "COURSE-CORE-COUPLES";
    CourseIdEnum["course_core_solo"] = "COURSE-CORE-SOLO";
    CourseIdEnum["course_destress_your_relationship"] = "course-destress-your-relationship";
    CourseIdEnum["course_destress_your_relationship_toolbox"] = "course-destress-your-relationship-toolbox";
    CourseIdEnum["course_sharing_appreciation"] = "course-sharing-appreciation";
    CourseIdEnum["course_dealing_with_rejection"] = "course-dealing-with-rejection";
    CourseIdEnum["course_navigating_your_differences"] = "course-navigating-your-differences";
    CourseIdEnum["course_becoming_a_better_team"] = "course-becoming-a-better-team";
    CourseIdEnum["course_surviving_the_holidays"] = "course-surviving-the-holidays";
    CourseIdEnum["course_love_languages"] = "course-love-languages";
    CourseIdEnum["course_new_year_relationship_resolutions"] = "course_new_year_relationship_resolutions";
    CourseIdEnum["course_emotional_intelligence"] = "course_emotional_intelligence";
    CourseIdEnum["valentines_day_toolkit"] = "valentines_day_toolkit";
    CourseIdEnum["course_how_to_talk_about_money"] = "course_how_to_talk_about_money";
})(CourseIdEnum || (CourseIdEnum = {}));
