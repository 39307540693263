export var GQLAdminPushMethod;
(function (GQLAdminPushMethod) {
    GQLAdminPushMethod["BRAZE"] = "BRAZE";
    GQLAdminPushMethod["FCM"] = "FCM";
})(GQLAdminPushMethod || (GQLAdminPushMethod = {}));
export var GQLAdminPushType;
(function (GQLAdminPushType) {
    GQLAdminPushType["DEBUG_OPEN"] = "DEBUG_OPEN";
    GQLAdminPushType["DEBUG_RECEIVE"] = "DEBUG_RECEIVE";
    GQLAdminPushType["DEFAULT"] = "DEFAULT";
    GQLAdminPushType["PARTNER_LINKING_SUCCESSFUL"] = "PARTNER_LINKING_SUCCESSFUL";
})(GQLAdminPushType || (GQLAdminPushType = {}));
export var GQLAdminRole;
(function (GQLAdminRole) {
    GQLAdminRole["CONTENT_CREATOR"] = "CONTENT_CREATOR";
    GQLAdminRole["PARTNERSHIPS_MANAGER"] = "PARTNERSHIPS_MANAGER";
    GQLAdminRole["SUPER_ADMIN"] = "SUPER_ADMIN";
    GQLAdminRole["USER_SUPPORT"] = "USER_SUPPORT";
})(GQLAdminRole || (GQLAdminRole = {}));
export var GQLCompatibilityStatus;
(function (GQLCompatibilityStatus) {
    GQLCompatibilityStatus["blocked"] = "blocked";
    GQLCompatibilityStatus["deprecated"] = "deprecated";
    GQLCompatibilityStatus["supported"] = "supported";
})(GQLCompatibilityStatus || (GQLCompatibilityStatus = {}));
export var GQLContentType;
(function (GQLContentType) {
    GQLContentType["Article"] = "Article";
    GQLContentType["Course"] = "Course";
    GQLContentType["Unknown"] = "Unknown";
})(GQLContentType || (GQLContentType = {}));
export var GQLDiscountPercentage;
(function (GQLDiscountPercentage) {
    GQLDiscountPercentage["PERCENT_25"] = "PERCENT_25";
    GQLDiscountPercentage["PERCENT_50"] = "PERCENT_50";
    GQLDiscountPercentage["PERCENT_100"] = "PERCENT_100";
})(GQLDiscountPercentage || (GQLDiscountPercentage = {}));
export var GQLExperimentName;
(function (GQLExperimentName) {
    /** @deprecated Field no longer supported */
    GQLExperimentName["AB_SUBSCRIPTION_SCREEN_JUNE_2022"] = "AB_SUBSCRIPTION_SCREEN_JUNE_2022";
    /** @deprecated Field no longer supported */
    GQLExperimentName["ASSESSMENT_SEP_2021"] = "ASSESSMENT_SEP_2021";
    /** @deprecated Field no longer supported */
    GQLExperimentName["ONBOARDING_SUBSCRIPTION_SCREEN"] = "ONBOARDING_SUBSCRIPTION_SCREEN";
    /** @deprecated Field no longer supported */
    GQLExperimentName["PARTNER_SOLO_SPLIT"] = "PARTNER_SOLO_SPLIT";
    /** @deprecated Field no longer supported */
    GQLExperimentName["PRE_ASSESSMENT_INTRO_VIDEO_SEP_2021"] = "PRE_ASSESSMENT_INTRO_VIDEO_SEP_2021";
    /** @deprecated Field no longer supported */
    GQLExperimentName["PRICING_EXPERIMENT_JULY_2021"] = "PRICING_EXPERIMENT_JULY_2021";
})(GQLExperimentName || (GQLExperimentName = {}));
export var GQLExperimentType;
(function (GQLExperimentType) {
    GQLExperimentType["onboarding"] = "onboarding";
    GQLExperimentType["other"] = "other";
})(GQLExperimentType || (GQLExperimentType = {}));
export var GQLFeatureFlagName;
(function (GQLFeatureFlagName) {
    GQLFeatureFlagName["ADD_COURSES_TO_LIBRARY_JULY_2022"] = "ADD_COURSES_TO_LIBRARY_JULY_2022";
    GQLFeatureFlagName["ASSESSMENT_OUTCOME"] = "ASSESSMENT_OUTCOME";
    GQLFeatureFlagName["BLOG_FAVORITE"] = "BLOG_FAVORITE";
    GQLFeatureFlagName["BLOG_SHARING"] = "BLOG_SHARING";
    GQLFeatureFlagName["COACHING_EXPERIMENT"] = "COACHING_EXPERIMENT";
    /** @deprecated Field no longer supported */
    GQLFeatureFlagName["DAILY_QUESTIONS_MAY_2022"] = "DAILY_QUESTIONS_MAY_2022";
    GQLFeatureFlagName["ENABLED_SESSION_BATCHES"] = "ENABLED_SESSION_BATCHES";
    GQLFeatureFlagName["FIREBASE_PASSWORDLESS"] = "FIREBASE_PASSWORDLESS";
    GQLFeatureFlagName["INTERCEPT_SURVEY_V2"] = "INTERCEPT_SURVEY_V2";
    GQLFeatureFlagName["JOURNAL_FEELINGS"] = "JOURNAL_FEELINGS";
    GQLFeatureFlagName["LOCKING_MECHANISM"] = "LOCKING_MECHANISM";
    GQLFeatureFlagName["NEW_BLOG_POST_SCREEN"] = "NEW_BLOG_POST_SCREEN";
    GQLFeatureFlagName["NEW_EXPLORE_LIBRARY"] = "NEW_EXPLORE_LIBRARY";
    GQLFeatureFlagName["NEW_HOME_SEP_2022"] = "NEW_HOME_SEP_2022";
    GQLFeatureFlagName["NEW_LOGIN_SCREEN_OCT_2021"] = "NEW_LOGIN_SCREEN_OCT_2021";
    GQLFeatureFlagName["OPT_IN"] = "OPT_IN";
    GQLFeatureFlagName["OVERRIDE_SUBSCRIPTION_STATUS"] = "OVERRIDE_SUBSCRIPTION_STATUS";
    GQLFeatureFlagName["PARTNER_PROGRESS_SYNCING"] = "PARTNER_PROGRESS_SYNCING";
    GQLFeatureFlagName["PARTNER_QUESTIONS"] = "PARTNER_QUESTIONS";
    GQLFeatureFlagName["PASSWORD_LOGIN"] = "PASSWORD_LOGIN";
    /** @deprecated Field no longer supported */
    GQLFeatureFlagName["PAYMENT_COUPON"] = "PAYMENT_COUPON";
    GQLFeatureFlagName["PAYWALL_ENABLED"] = "PAYWALL_ENABLED";
    GQLFeatureFlagName["PERSONALISATION_SURVEY"] = "PERSONALISATION_SURVEY";
    GQLFeatureFlagName["PMF_SURVEY_ENABLED"] = "PMF_SURVEY_ENABLED";
    GQLFeatureFlagName["SERVER_TODAY_JUL_2022"] = "SERVER_TODAY_JUL_2022";
    GQLFeatureFlagName["SESSION_AB_TESTS"] = "SESSION_AB_TESTS";
    GQLFeatureFlagName["SHARE_DAILY_QUESTION_OCT_2022"] = "SHARE_DAILY_QUESTION_OCT_2022";
    GQLFeatureFlagName["SMART_JOURNAL_NOV_2022"] = "SMART_JOURNAL_NOV_2022";
    GQLFeatureFlagName["SUBSCRIPTION_SHARING"] = "SUBSCRIPTION_SHARING";
    GQLFeatureFlagName["TOPIC_PERSONALISATION_NOV_2022"] = "TOPIC_PERSONALISATION_NOV_2022";
    GQLFeatureFlagName["USER_NAME_SETUP_JUNE_2022"] = "USER_NAME_SETUP_JUNE_2022";
    /** @deprecated Field no longer supported */
    GQLFeatureFlagName["WEB_FIREBASE_PASSWORDLESS"] = "WEB_FIREBASE_PASSWORDLESS";
    GQLFeatureFlagName["WEB_LOGIN"] = "WEB_LOGIN";
    GQLFeatureFlagName["WEB_NEW_SIGNUP_JUL_2022"] = "WEB_NEW_SIGNUP_JUL_2022";
    GQLFeatureFlagName["WEB_PAYMENTS"] = "WEB_PAYMENTS";
    GQLFeatureFlagName["XP_VALUES"] = "XP_VALUES";
})(GQLFeatureFlagName || (GQLFeatureFlagName = {}));
export var GQLFeelingJournalEntryFeeling;
(function (GQLFeelingJournalEntryFeeling) {
    GQLFeelingJournalEntryFeeling["BAD"] = "BAD";
    GQLFeelingJournalEntryFeeling["GOOD"] = "GOOD";
    GQLFeelingJournalEntryFeeling["GREAT"] = "GREAT";
    GQLFeelingJournalEntryFeeling["NOT_GREAT"] = "NOT_GREAT";
    GQLFeelingJournalEntryFeeling["OK"] = "OK";
})(GQLFeelingJournalEntryFeeling || (GQLFeelingJournalEntryFeeling = {}));
export var GQLFeelingJournalEntryFeelingDescription;
(function (GQLFeelingJournalEntryFeelingDescription) {
    GQLFeelingJournalEntryFeelingDescription["AMAZED"] = "AMAZED";
    GQLFeelingJournalEntryFeelingDescription["ANXIOUS"] = "ANXIOUS";
    GQLFeelingJournalEntryFeelingDescription["APPRECIATED"] = "APPRECIATED";
    GQLFeelingJournalEntryFeelingDescription["BORED"] = "BORED";
    GQLFeelingJournalEntryFeelingDescription["CONFUSED"] = "CONFUSED";
    GQLFeelingJournalEntryFeelingDescription["CONNECTED"] = "CONNECTED";
    GQLFeelingJournalEntryFeelingDescription["DISAPPOINTED"] = "DISAPPOINTED";
    GQLFeelingJournalEntryFeelingDescription["DISTANT"] = "DISTANT";
    GQLFeelingJournalEntryFeelingDescription["EXCITED"] = "EXCITED";
    GQLFeelingJournalEntryFeelingDescription["FINE"] = "FINE";
    GQLFeelingJournalEntryFeelingDescription["FRUSTRATED"] = "FRUSTRATED";
    GQLFeelingJournalEntryFeelingDescription["GRATEFUL"] = "GRATEFUL";
    GQLFeelingJournalEntryFeelingDescription["GUILTY"] = "GUILTY";
    GQLFeelingJournalEntryFeelingDescription["HAPPY"] = "HAPPY";
    GQLFeelingJournalEntryFeelingDescription["HOPEFUL"] = "HOPEFUL";
    GQLFeelingJournalEntryFeelingDescription["HURT"] = "HURT";
    GQLFeelingJournalEntryFeelingDescription["INSECURE"] = "INSECURE";
    GQLFeelingJournalEntryFeelingDescription["IN_LOVE"] = "IN_LOVE";
    GQLFeelingJournalEntryFeelingDescription["JEALOUS"] = "JEALOUS";
    GQLFeelingJournalEntryFeelingDescription["LONELY"] = "LONELY";
    GQLFeelingJournalEntryFeelingDescription["MAD"] = "MAD";
    GQLFeelingJournalEntryFeelingDescription["NOTHING"] = "NOTHING";
    GQLFeelingJournalEntryFeelingDescription["PEACEFUL"] = "PEACEFUL";
    GQLFeelingJournalEntryFeelingDescription["PROUD"] = "PROUD";
    GQLFeelingJournalEntryFeelingDescription["RELAXED"] = "RELAXED";
    GQLFeelingJournalEntryFeelingDescription["SAFE"] = "SAFE";
    GQLFeelingJournalEntryFeelingDescription["SCARED"] = "SCARED";
    GQLFeelingJournalEntryFeelingDescription["STRESSED"] = "STRESSED";
    GQLFeelingJournalEntryFeelingDescription["TIRED"] = "TIRED";
    GQLFeelingJournalEntryFeelingDescription["UNAPPRECIATED"] = "UNAPPRECIATED";
})(GQLFeelingJournalEntryFeelingDescription || (GQLFeelingJournalEntryFeelingDescription = {}));
export var GQLFeelingJournalEntryFeelingReason;
(function (GQLFeelingJournalEntryFeelingReason) {
    GQLFeelingJournalEntryFeelingReason["ARGUMENT"] = "ARGUMENT";
    GQLFeelingJournalEntryFeelingReason["CHILDREN"] = "CHILDREN";
    GQLFeelingJournalEntryFeelingReason["COMPATIBILITY"] = "COMPATIBILITY";
    GQLFeelingJournalEntryFeelingReason["ETHICS"] = "ETHICS";
    GQLFeelingJournalEntryFeelingReason["FAMILY"] = "FAMILY";
    GQLFeelingJournalEntryFeelingReason["FRIENDS"] = "FRIENDS";
    GQLFeelingJournalEntryFeelingReason["HEALTH"] = "HEALTH";
    GQLFeelingJournalEntryFeelingReason["HOLIDAY"] = "HOLIDAY";
    GQLFeelingJournalEntryFeelingReason["HOUSEWORK"] = "HOUSEWORK";
    GQLFeelingJournalEntryFeelingReason["INTERESTS"] = "INTERESTS";
    GQLFeelingJournalEntryFeelingReason["LIFESTYLE"] = "LIFESTYLE";
    GQLFeelingJournalEntryFeelingReason["MONEY"] = "MONEY";
    GQLFeelingJournalEntryFeelingReason["OTHER"] = "OTHER";
    GQLFeelingJournalEntryFeelingReason["OUR_FUTURE"] = "OUR_FUTURE";
    GQLFeelingJournalEntryFeelingReason["PARTNER"] = "PARTNER";
    GQLFeelingJournalEntryFeelingReason["RELIGION"] = "RELIGION";
    GQLFeelingJournalEntryFeelingReason["SEX"] = "SEX";
    GQLFeelingJournalEntryFeelingReason["SOMEONE_ELSE"] = "SOMEONE_ELSE";
    GQLFeelingJournalEntryFeelingReason["SPECIAL_MOMENT"] = "SPECIAL_MOMENT";
    GQLFeelingJournalEntryFeelingReason["WORK"] = "WORK";
})(GQLFeelingJournalEntryFeelingReason || (GQLFeelingJournalEntryFeelingReason = {}));
export var GQLGrantAccessDuration;
(function (GQLGrantAccessDuration) {
    GQLGrantAccessDuration["Daily"] = "Daily";
    GQLGrantAccessDuration["Lifetime"] = "Lifetime";
    GQLGrantAccessDuration["Monthly"] = "Monthly";
    GQLGrantAccessDuration["SixMonth"] = "SixMonth";
    GQLGrantAccessDuration["ThreeMonth"] = "ThreeMonth";
    GQLGrantAccessDuration["TwoMonth"] = "TwoMonth";
    GQLGrantAccessDuration["Weekly"] = "Weekly";
    GQLGrantAccessDuration["Yearly"] = "Yearly";
})(GQLGrantAccessDuration || (GQLGrantAccessDuration = {}));
export var GQLIAPProvider;
(function (GQLIAPProvider) {
    GQLIAPProvider["APPLE"] = "APPLE";
    GQLIAPProvider["GOOGLE"] = "GOOGLE";
})(GQLIAPProvider || (GQLIAPProvider = {}));
export var GQLOrderingEnum;
(function (GQLOrderingEnum) {
    GQLOrderingEnum["ASC"] = "ASC";
    GQLOrderingEnum["DESC"] = "DESC";
})(GQLOrderingEnum || (GQLOrderingEnum = {}));
export var GQLPhoneHomeEvent;
(function (GQLPhoneHomeEvent) {
    GQLPhoneHomeEvent["viewHomeScreen"] = "viewHomeScreen";
    GQLPhoneHomeEvent["viewSubscriptionPage"] = "viewSubscriptionPage";
})(GQLPhoneHomeEvent || (GQLPhoneHomeEvent = {}));
export var GQLPlatform;
(function (GQLPlatform) {
    GQLPlatform["android"] = "android";
    GQLPlatform["ios"] = "ios";
    GQLPlatform["macos"] = "macos";
    GQLPlatform["server"] = "server";
    GQLPlatform["web"] = "web";
    GQLPlatform["windows"] = "windows";
})(GQLPlatform || (GQLPlatform = {}));
export var GQLPurchaseStore;
(function (GQLPurchaseStore) {
    GQLPurchaseStore["app_store"] = "app_store";
    GQLPurchaseStore["mac_app_store"] = "mac_app_store";
    GQLPurchaseStore["play_store"] = "play_store";
    GQLPurchaseStore["promotional"] = "promotional";
    GQLPurchaseStore["stripe"] = "stripe";
})(GQLPurchaseStore || (GQLPurchaseStore = {}));
export var GQLPurchaseSubscriptionPeriodType;
(function (GQLPurchaseSubscriptionPeriodType) {
    GQLPurchaseSubscriptionPeriodType["intro"] = "intro";
    GQLPurchaseSubscriptionPeriodType["normal"] = "normal";
    GQLPurchaseSubscriptionPeriodType["trial"] = "trial";
})(GQLPurchaseSubscriptionPeriodType || (GQLPurchaseSubscriptionPeriodType = {}));
export var GQLPushTokenType;
(function (GQLPushTokenType) {
    GQLPushTokenType["apns"] = "apns";
    GQLPushTokenType["fcm"] = "fcm";
    GQLPushTokenType["gcm"] = "gcm";
})(GQLPushTokenType || (GQLPushTokenType = {}));
export var GQLQuestionnaireResponseBodyVersion;
(function (GQLQuestionnaireResponseBodyVersion) {
    GQLQuestionnaireResponseBodyVersion["V1_0_0"] = "V1_0_0";
})(GQLQuestionnaireResponseBodyVersion || (GQLQuestionnaireResponseBodyVersion = {}));
export var GQLSessionCategory;
(function (GQLSessionCategory) {
    GQLSessionCategory["ACTIVITY"] = "ACTIVITY";
    GQLSessionCategory["LEARN"] = "LEARN";
    GQLSessionCategory["SOLO_ACTIVITY"] = "SOLO_ACTIVITY";
    GQLSessionCategory["SOLO_TOUCH"] = "SOLO_TOUCH";
    GQLSessionCategory["TALK"] = "TALK";
    GQLSessionCategory["TOUCH"] = "TOUCH";
})(GQLSessionCategory || (GQLSessionCategory = {}));
export var GQLTodaysTaskStatus;
(function (GQLTodaysTaskStatus) {
    GQLTodaysTaskStatus["Completed"] = "Completed";
    GQLTodaysTaskStatus["InProgress"] = "InProgress";
    GQLTodaysTaskStatus["NotStarted"] = "NotStarted";
})(GQLTodaysTaskStatus || (GQLTodaysTaskStatus = {}));
export var GQLTodaysTaskType;
(function (GQLTodaysTaskType) {
    GQLTodaysTaskType["Article"] = "Article";
    GQLTodaysTaskType["DailyQuestion"] = "DailyQuestion";
    GQLTodaysTaskType["Journal"] = "Journal";
    GQLTodaysTaskType["Session"] = "Session";
})(GQLTodaysTaskType || (GQLTodaysTaskType = {}));
