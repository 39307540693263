import { getType } from "typesafe-actions";
export var LoadingState;
(function (LoadingState) {
    LoadingState["Initial"] = "Initial";
    LoadingState["Loading"] = "Loading";
    LoadingState["Success"] = "Success";
    LoadingState["Failure"] = "Failure";
    LoadingState["Cancelled"] = "Cancelled";
})(LoadingState || (LoadingState = {}));
export var createLoadingData = function (actionCreator, action) {
    var type = action.type;
    switch (type) {
        case getType(actionCreator.request):
            return { state: LoadingState.Loading };
        case getType(actionCreator.success):
            return {
                state: LoadingState.Success,
                data: action.payload
            };
        case getType(actionCreator.failure):
            return {
                state: LoadingState.Failure,
                error: action.payload
            };
        case "cancel" in actionCreator && getType(actionCreator.cancel):
            return {
                state: LoadingState.Cancelled
            };
        default:
            throw new Error("Unknown action type");
    }
};
export var createServerData = function (actionCreator, action, previousState) {
    var type = action.type;
    switch (type) {
        case getType(actionCreator.request):
            return { data: previousState.data, state: LoadingState.Loading };
        case getType(actionCreator.success):
            return {
                state: LoadingState.Success,
                data: action.payload
            };
        case getType(actionCreator.failure):
            return {
                state: LoadingState.Failure,
                data: previousState.data,
                error: action.payload
            };
        case "cancel" in actionCreator && getType(actionCreator.cancel):
            return {
                data: previousState.data,
                state: LoadingState.Cancelled
            };
        default:
            throw new Error("Unknown action type");
    }
};
