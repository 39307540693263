var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { QuestionnaireName } from "@blueheart/api-spec/src/sharedTypes";
import { QUERY_GET_QUESTIONNAIRE_RESPONSES } from "@blueheart/api-spec/src/adminQueries";
import Table from "react-bootstrap/Table";
import ProgressBar from "react-bootstrap/ProgressBar";
import styled from "styled-components";
var emailBlacklist = new Set([
    "robbie.coomber@gmail.com",
    "sachin@spoorex.com",
    "sabrina.lavinia.szabo@gmail.com"
]);
var Unselectable = styled(function (props) {
    var 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children = props.children, rest = __rest(props, ["children"]);
    return _jsx("span", __assign({}, rest));
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &::before {\n    content: \"", "\";\n  }\n"], ["\n  &::before {\n    content: \"", "\";\n  }\n"])), function (props) { return props.children; });
var displayAnswer = function (answer) {
    if (answer.text) {
        return answer.text.value;
    }
    if (answer.multiSelect) {
        return answer.multiSelect.map(function (ms) { return ms.value; }).join(", ");
    }
    if (answer.select) {
        return answer.select.value;
    }
    if (answer.boolean) {
        return answer.boolean.value ? "true" : "false";
    }
    if (answer.numeric) {
        var _a = answer.numeric, minValue = _a.minValue, maxValue = _a.maxValue, value = _a.value;
        return (_jsxs("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: [_jsx(Unselectable, { children: minValue }), _jsx(ProgressBar, { now: value, label: value, style: { flexGrow: 1 } }), _jsx(Unselectable, { children: maxValue })] })));
    }
    console.error("No renderer for this answer type", answer);
};
var formatIsoDateToDDMMYYYY = function (date) {
    var asDate = new Date(date);
    var dateTimeFormat = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    });
    var parts = dateTimeFormat.formatToParts(asDate);
    if (!parts[0] || !parts[2] || !parts[4]) {
        throw new Error("Error in parse date in formatIsoDateToDDMMYYYY");
    }
    var _a = __read(parts, 5), month = _a[0].value, day = _a[2].value, year = _a[4].value;
    return "".concat(day, "/").concat(month, "/").concat(year);
};
var filterRows = function (rows, name) {
    var filteredRows = rows.filter(function (_a) {
        var response = _a.response;
        return response.user &&
            response.user.email &&
            !response.user.email.endsWith("@blueheart.io") &&
            !response.user.email.endsWith("@example.com") &&
            !emailBlacklist.has(response.user.email);
    });
    var filteringMessages = [
        "Filtered ".concat(rows.length - filteredRows.length, " of ").concat(rows.length, " @blueheart.io , @example.com , and blacklisted email address responses")
    ];
    if (name === QuestionnaireName.baseline) {
        var newFilteredRows = filteredRows.filter(function (_a) {
            var answers = _a.answers;
            return !answers.every(function (answer) {
                return answer &&
                    answer.answer.numeric &&
                    answer.answer.numeric.value === 50;
            });
        });
        filteringMessages.push("Filtered ".concat(filteredRows.length - newFilteredRows.length, " of ").concat(filteredRows.length, " all 50s response"));
        filteredRows = newFilteredRows;
    }
    return {
        filteringMessages: filteringMessages,
        filteredRows: filteredRows
    };
};
export var QuestionnaireResponsesTable = function (_a) {
    var name = _a.name;
    var _b = useQuery(QUERY_GET_QUESTIONNAIRE_RESPONSES, {
        variables: {
            name: name
        }
    }), loading = _b.loading, error = _b.error, data = _b.data, refetch = _b.refetch;
    var _c = __read(useMemo(function () {
        if (!data || !data.getQuestionnaireResponses || loading) {
            return [undefined, undefined];
        }
        var responses = data.getQuestionnaireResponses.questionnaireResponses;
        var headingsSet = new Set();
        var headings = [];
        responses.forEach(function (response) {
            if (!response.body) {
                return;
            }
            response.body.responses.forEach(function (_a) {
                var question = _a.question;
                if (!headingsSet.has(question)) {
                    headingsSet.add(question);
                    headings.push(question);
                }
            });
        });
        var rows = responses.map(function (response) {
            return {
                response: response,
                answers: headings.map(function (heading) {
                    return (response.body &&
                        response.body.responses.find(function (_a) {
                            var question = _a.question;
                            return question === heading;
                        }));
                })
            };
        });
        return [headings, rows];
    }, [data, loading]), 2), headings = _c[0], rows = _c[1];
    if (error) {
        return (_jsxs(Container, { children: [_jsx(Button, __assign({ onClick: function () { return refetch().catch(console.error); } }, { children: "Retry" })), _jsx("div", { children: JSON.stringify(error) })] }));
    }
    if (!data || !data.getQuestionnaireResponses || loading) {
        return (_jsx(Container, { children: _jsx("span", { children: "Loading" }) }));
    }
    if (!headings || !rows) {
        return (_jsx(Container, { children: _jsx("span", { children: "No responses" }) }));
    }
    var _d = filterRows(rows, name), filteredRows = _d.filteredRows, filteringMessages = _d.filteringMessages;
    return (_jsxs(Container, __assign({ fluid: true }, { children: [filteringMessages.map(function (m) { return (_jsx("div", { children: m })); }), _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Email" }), _jsx("th", { children: "Date" }), headings.map(function (heading) { return (_jsx("th", { children: heading }, heading)); })] }) }), _jsx("tbody", { children: filteredRows.map(function (_a) {
                            var response = _a.response, answers = _a.answers;
                            return (_jsxs("tr", { children: [_jsx("td", { children: response.user && response.user.email }), _jsx("td", { children: formatIsoDateToDDMMYYYY(response.dateSubmitted) }), answers.map(function (answer, n) {
                                        return (_jsx("td", { children: answer && displayAnswer(answer.answer) }, n));
                                    })] }, response.id));
                        }) })] })] })));
};
export var Superhuman = function () { return (_jsx(QuestionnaireResponsesTable, { name: QuestionnaireName.superhuman })); };
export var UserHealth = function () { return (_jsx(QuestionnaireResponsesTable, { name: QuestionnaireName.baseline })); };
export var InterceptSurvey = function () { return (_jsx(QuestionnaireResponsesTable, { name: QuestionnaireName.intercept })); };
var templateObject_1;
