export var isNotNil = function (x) {
    return !(x === undefined || x === null);
};
export var isNil = function (x) {
    return x === undefined || x === null;
};
export var isTupleNotNil = function (x) {
    return (!(x[0] === undefined || x[0] === null) &&
        !(x[1] === undefined || x[1] === null));
};
export var isSecondNotNil = function (x) {
    return !(x[1] === undefined || x[1] === null);
};
