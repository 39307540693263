var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Button, Container, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import { MUTATION_UPDATE_EXPERIMENT, QUERY_GET_ACTIVE_EXPERIMENTS, QUERY_GET_EXPERIMENTS } from "@blueheart/api-spec/src/adminQueries";
var Control = Form.Control, Label = Form.Label, Group = Form.Group, Text = Form.Text;
var editFormSchema = Yup.object().shape({
    startDate: Yup.date(),
    endDate: Yup.date(),
    winningVariant: Yup.string()
});
export var EditExperiment = function (props) {
    var _a = __read(useMutation(MUTATION_UPDATE_EXPERIMENT, {
        refetchQueries: [
            { query: QUERY_GET_EXPERIMENTS },
            { query: QUERY_GET_ACTIVE_EXPERIMENTS }
        ]
    }), 2), doMutation = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    return (_jsxs(Container, { children: [_jsx(Formik, __assign({ validationSchema: editFormSchema, initialValues: {
                    startDate: props.initialStartDate,
                    endDate: props.initialEndDate,
                    winningVariant: props.initialWinningVariant
                }, onSubmit: function (values, _a) {
                    var resetForm = _a.resetForm;
                    doMutation({
                        variables: {
                            experimentId: props.experimentId,
                            startDate: values.startDate,
                            endDate: values.endDate,
                            winningVariant: values.winningVariant
                        }
                    })
                        .then(function (result) {
                        var _a;
                        if (result.errors) {
                            console.error(result.errors);
                        }
                        else if ((_a = result.data) === null || _a === void 0 ? void 0 : _a.updateExperimentAsAdmin) {
                            resetForm();
                            props.onEdited();
                        }
                    })
                        .catch(function (e) { return console.error(e); });
                } }, { children: function (_a) {
                    var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, handleBlur = _a.handleBlur, values = _a.values, isValid = _a.isValid, dirty = _a.dirty, touched = _a.touched;
                    return (_jsxs(Form, __assign({ onSubmit: handleSubmit }, { children: [_jsxs("h2", { children: ["Editing experiment ", props.experimentName] }), _jsxs(Group, { children: [_jsx(Label, { children: "Start date" }), _jsx(Control, { type: "date", name: "startDate", value: values.startDate, onChange: handleChange })] }), _jsxs(Group, { children: [_jsx(Label, { children: "End date" }), _jsx(Control, { type: "date", name: "endDate", value: values.endDate, onChange: handleChange })] }), _jsxs(Group, { children: [_jsx(Label, { children: "Winning Variant" }), _jsx(Control, { type: "text", name: "winningVariant", value: values.winningVariant, onChange: handleChange, onBlur: handleBlur }), touched.winningVariant &&
                                        values.winningVariant &&
                                        !values.endDate && (_jsx(Text, __assign({ className: "form-error" }, { children: "Warning: setting Winning Variant without setting an end date will end the experiment immediately" })))] }), _jsx(Group, { children: _jsx(Button, __assign({ type: "submit", disabled: !isValid || loading || !dirty }, { children: "Save" })) })] })));
                } })), error && (_jsxs(_Fragment, { children: [_jsx(_Fragment, { children: error.message }), error.graphQLErrors.map(function (e) {
                        return _jsx(_Fragment, { children: e.message });
                    })] }))] }));
};
