var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { MUTATION_RUN_TASK } from "@blueheart/api-spec/src/adminQueries";
import { Card, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { Header } from "./components/Header";
var SpaceRow = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 5px;\n"], ["\n  margin: 5px;\n"])));
var tasks = [
    { task: "user-sheet", label: "Update User Sheet" },
    { task: "etl", label: "ETL data from prod DB to BigQuery" },
    {
        task: "refresh-bigquery-views",
        label: "Refresh BigQuery view definitions"
    },
    {
        task: "updateAnalyticsUserIdentityPropertiesFromAssessment",
        label: "Update All Analytics User Properties from Assessment"
    },
    {
        task: "updateHasUserPaidInfo",
        label: "Update has_ever_paid information for users that have started a trial"
    },
    {
        task: "getBrazeImportCsv",
        label: "Get a CSV of the last month's active user emails and analytics ids"
    },
    {
        task: "sendTrialCancellationSurvey",
        label: "Send trial cancellation survey to user by email,currency e.g. bob@example.com,USD"
    },
    {
        task: "stripeSetup",
        label: "Create payment links and prices in stripe"
    },
    {
        task: "assignAccountToUser",
        label: "Create and assign firebase account to user. Args: userId email(optional)"
    },
    {
        task: "sendBrazeEventWithTodayAssignments",
        label: "Send event with today assignments. Args: userId;dqId;dqTitle (; is delimiter)"
    },
    {
        task: "notifyUsersTask",
        label: "Send notification to users defined in json",
        isJsonInput: true,
        defaultArgs: "{ \n  \"title\": \"\", \n \"message\": \"\", \n \"userIds\": [] \n}"
    },
    {
        task: "sendTestServerEvent",
        label: "Send test server analytics event. Args: email address of user to send event for"
    },
    {
        task: "assignChatTokenToUsers",
        label: "Refresh chat token for specified user ids",
        isJsonInput: true,
        defaultArgs: "[]"
    },
    {
        task: "importFromRevenueCatCsv",
        label: "Import user info from revenuecat CSV export",
        isFileInput: true,
        defaultArgs: ""
    },
    {
        task: "backfillWordCloudDataInSmartJournalEntries",
        label: "Backfill word cloud data in smart journal entries"
    },
    {
        task: "getContentById",
        label: "Check contentful service returns expected content. Args: contentId"
    }
];
export var TaskView = function (_a) {
    var taskDefinition = _a.taskDefinition;
    var _b = __read(useState(taskDefinition.defaultArgs), 2), args = _b[0], setArgs = _b[1];
    var _c = __read(useMutation(MUTATION_RUN_TASK, {
        variables: {
            task: taskDefinition.task,
            args: args
        }
    }), 2), runTask = _c[0], _d = _c[1], loading = _d.loading, error = _d.error, data = _d.data;
    return (_jsx(Card, { children: _jsxs(Container, { children: [_jsx(Row, { children: _jsx(Col, __assign({ style: { paddingTop: 10 } }, { children: _jsx("h3", { children: taskDefinition.label }) })) }), _jsx(Row, { children: _jsxs(Col, { children: [taskDefinition.isJsonInput ? (_jsxs(Form.Group, __assign({ className: "mb-3" }, { children: [_jsx(Form.Label, { children: "Task Arguments: " }), _jsx(Form.Control, { as: "textarea", rows: 5, value: args, onChange: function (event) {
                                            setArgs(event.target.value);
                                        } })] }))) : taskDefinition.isFileInput ? (_jsxs(Form.Group, __assign({ className: "mb-3" }, { children: [_jsx(Form.Label, { children: "Task Arguments: " }), _jsx(Form.Text, { children: "Choose the file to upload" }), _jsx("input", { type: "file", multiple: false, onChange: function (e) {
                                            var _a;
                                            var file = (_a = e.currentTarget.files) === null || _a === void 0 ? void 0 : _a[0];
                                            if (!file) {
                                                setArgs(undefined);
                                                return;
                                            }
                                            // set the arg string to the base64 encoded blob
                                            var reader = new FileReader();
                                            reader.addEventListener("load", function () {
                                                setArgs(String(reader.result));
                                            }, false);
                                            reader.readAsDataURL(file);
                                        } })] }))) : (_jsxs(Form.Group, __assign({ className: "mb-3" }, { children: [_jsx(Form.Label, { children: "Task Arguments: " }), _jsx(Form.Text, { children: "This text box can send a string of args to the server side task (e.g. `offset limit` like `0 100`)" }), _jsx(Form.Control, { value: args, onChange: function (event) {
                                            setArgs(event.target.value);
                                        }, placeholder: "" })] }))), _jsx("div", __assign({ className: "d-grid gap-2", style: {
                                    borderBottomStyle: "double",
                                    paddingBottom: 10,
                                    marginBottom: 10
                                } }, { children: _jsx(Button, __assign({ onClick: function () {
                                        runTask().catch(console.error);
                                    }, disabled: loading }, { children: "Execute" })) })), _jsxs(Form.Group, __assign({ className: "mb-3" }, { children: [_jsx(Form.Label, { children: "Results: " }), _jsx("pre", { children: !loading
                                            ? JSON.stringify(error, null, 2) || (data === null || data === void 0 ? void 0 : data.runTask) || ""
                                            : "loading..." })] }))] }) })] }) }));
};
export var TaskList = function (_a) {
    var onTaskSelected = _a.onTaskSelected;
    return (_jsx(Card, { children: tasks.map(function (taskDefinition) { return (_jsx(SpaceRow, { children: _jsx(Button, __assign({ onClick: function () { return onTaskSelected(taskDefinition); } }, { children: taskDefinition.label })) }, taskDefinition.label)); }) }));
};
export var Tasks = function () {
    var _a = __read(useState(undefined), 2), task = _a[0], setTask = _a[1];
    return (_jsxs(Container, { children: [_jsx(Header, __assign({ title: "Tasks" }, { children: task && (_jsx(Button, __assign({ variant: "link", onClick: function () { return setTask(undefined); } }, { children: "Back to list" }))) })), !task && _jsx(TaskList, { onTaskSelected: function (task) { return setTask(task); } }), task && _jsx(TaskView, { taskDefinition: task })] }));
};
var templateObject_1;
