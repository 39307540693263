var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { MUTATION_ADD_EMAIL_AS_ADMIN } from "@blueheart/api-spec/src/adminQueries";
export var AddEmailButton = function (_a) {
    var _b, _c;
    var user = _a.user;
    var _d = __read(useState(false), 2), isModalOpen = _d[0], setIsModalOpen = _d[1];
    var _e = __read(useState(""), 2), email = _e[0], setEmail = _e[1];
    var _f = __read(useMutation(MUTATION_ADD_EMAIL_AS_ADMIN, {
        refetchQueries: "all"
    }), 2), addEmail = _f[0], _g = _f[1], loading = _g.loading, error = _g.error, data = _g.data;
    if (user.email) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ onClick: function () { return setIsModalOpen(true); } }, { children: "Add Email" })), _jsxs(Modal, __assign({ show: isModalOpen, onHide: function () { return setIsModalOpen(false); } }, { children: [_jsx(Modal.Header, __assign({ closeButton: true }, { children: _jsx(Modal.Title, { children: "Add Email" }) })), _jsxs(Modal.Body, { children: [_jsxs("p", { children: ["Add an email to ", _jsx("strong", { children: user.id })] }), _jsx("input", { type: "email", value: email, required: true, onChange: function (e) { return setEmail(e.currentTarget.value); } }), _jsxs("p", { children: ["This will also send the user a password reset email to ", email] }), _jsx(Button, __assign({ disabled: loading, onClick: function () {
                                    addEmail({
                                        variables: {
                                            userId: user.id,
                                            email: email
                                        }
                                    }).then(function (response) {
                                        var _a, _b;
                                        if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.addEmailAddressAsAdmin) === null || _b === void 0 ? void 0 : _b.__typename) ===
                                            "GQLForeignUserAsAdmin") {
                                            setIsModalOpen(false);
                                            setEmail("");
                                        }
                                    });
                                } }, { children: loading ? "Loading..." : "Add Email" })), _jsx("span", { children: (error === null || error === void 0 ? void 0 : error.message) ||
                                    ((_b = data === null || data === void 0 ? void 0 : data.addEmailAddressAsAdmin) === null || _b === void 0 ? void 0 : _b.__typename) !== "GQLForeignUserAsAdmin"
                                    ? (_c = data === null || data === void 0 ? void 0 : data.addEmailAddressAsAdmin) === null || _c === void 0 ? void 0 : _c.__typename
                                    : undefined })] })] }))] }));
};
