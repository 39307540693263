var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from "@apollo/client";
import { MUTATION_DELETE_USER_AS_ADMIN } from "@blueheart/api-spec/src/adminQueries";
import { Button, Card, Modal } from "react-bootstrap";
import { useMemo, useState } from "react";
import ms from "ms";
export var DangerZone = function (_a) {
    var _b;
    var user = _a.user;
    var _c = __read(useMutation(MUTATION_DELETE_USER_AS_ADMIN, {
        refetchQueries: "all"
    }), 2), doDelete = _c[0], _d = _c[1], loading = _d.loading, error = _d.error, data = _d.data;
    var _e = __read(useState(false), 2), isDeleteModalOpen = _e[0], setIsDeleteModalOpen = _e[1];
    var _f = __read(useState(""), 2), deleteModalConfirmId = _f[0], setDeleteModalConfirmId = _f[1];
    var creationTimeAgoString = useMemo(function () {
        if (!(user === null || user === void 0 ? void 0 : user.created)) {
            return undefined;
        }
        var now = new Date().getTime();
        var creationTime = new Date(user.created).getTime();
        var diff = now - creationTime;
        return ms(diff, { long: true });
    }, [user === null || user === void 0 ? void 0 : user.created]);
    return (_jsxs(Card, { children: [_jsx(Card.Header, { children: "Danger Zone" }), _jsxs(Card.Body, { children: [_jsx(Button, __assign({ variant: "danger", onClick: function () { return setIsDeleteModalOpen(true); } }, { children: "Delete User" })), _jsxs(Modal, __assign({ show: isDeleteModalOpen, onHide: function () { return setIsDeleteModalOpen(false); } }, { children: [_jsx(Modal.Header, __assign({ closeButton: true }, { children: _jsx(Modal.Title, { children: "Delete User" }) })), _jsxs(Modal.Body, { children: [_jsxs("p", { children: ["Are you sure you want to delete ", _jsx("strong", { children: user.id }), "?"] }), _jsxs("dl", { children: [_jsx("dt", { children: "Created" }), _jsxs("dd", { children: [creationTimeAgoString, " ago"] }), _jsx("dt", { children: "Email" }), _jsx("dd", { children: user.email || "n/a" })] }), _jsx("p", { children: "This action cannot be undone. Please type the user's ID to confirm." }), _jsx("input", { type: "text", value: deleteModalConfirmId, onChange: function (e) { return setDeleteModalConfirmId(e.currentTarget.value); } }), _jsx(Button, __assign({ disabled: loading || deleteModalConfirmId !== user.id, onClick: function () {
                                            doDelete({
                                                variables: {
                                                    id: user.id
                                                }
                                            }).then(function (response) {
                                                var _a, _b;
                                                if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.deleteUserAsAdmin) === null || _b === void 0 ? void 0 : _b.__typename) ===
                                                    "GQLSuccessResponse") {
                                                    setIsDeleteModalOpen(false);
                                                    setDeleteModalConfirmId("");
                                                }
                                            });
                                        } }, { children: loading ? "Loading..." : "Delete" })), _jsx("span", { children: error
                                            ? error.message
                                            : ((_b = data === null || data === void 0 ? void 0 : data.deleteUserAsAdmin) === null || _b === void 0 ? void 0 : _b.__typename) !== "GQLSuccessResponse"
                                                ? data === null || data === void 0 ? void 0 : data.deleteUserAsAdmin.__typename
                                                : undefined })] })] }))] })] }));
};
