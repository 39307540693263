var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import fromPairs from "lodash/fromPairs";
import toPairs from "lodash/toPairs";
export var swapNullForUndefined = function (x) {
    if (x === null) {
        return undefined;
    }
    return x;
};
export var swapNullForUndefinedInObject = function (x) {
    return fromPairs(toPairs(x).map(function (_a) {
        var _b = __read(_a, 2), k = _b[0], v = _b[1];
        if (v === null) {
            return [k, undefined];
        }
        return [k, v];
    }));
};
