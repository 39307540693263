var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Col, Row, Button, Table } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import format from "date-fns/format";
import { QUERY_GET_DAILY_QUESTION_RECORDS } from "@blueheart/api-spec/src/adminQueries";
import { useHistory, useParams } from "react-router-dom";
export var DailyQuestionRecords = function () {
    var _a, _b;
    var date = useParams().date;
    var history = useHistory();
    var _c = useQuery(QUERY_GET_DAILY_QUESTION_RECORDS, {
        variables: { date: date }
    }), data = _c.data, loading = _c.loading;
    if (loading) {
        return _jsx(_Fragment, { children: "Loading..." });
    }
    var stats = (_a = data === null || data === void 0 ? void 0 : data.content) === null || _a === void 0 ? void 0 : _a.dailyQuestionStats;
    return (_jsxs(Container, { children: [_jsxs(Row, { children: [_jsx(Col, __assign({ md: "1" }, { children: _jsx(Button, __assign({ style: { marginRight: 10 }, onClick: function () {
                                history.goBack();
                            } }, { children: "Back" })) })), _jsx(Col, { children: _jsxs("h1", { children: ["Daily Question Records for: ", format(new Date(date), "yyyy-MM-dd")] }) })] }), _jsx(Row, { children: _jsxs(Container, { children: [_jsxs(Row, { children: ["Asked: ", (stats === null || stats === void 0 ? void 0 : stats.askedTimes) || "-"] }), _jsxs(Row, { children: ["Answered: ", (stats === null || stats === void 0 ? void 0 : stats.answeredTimes) || "-"] }), _jsxs(Row, { children: ["Completed: ", (stats === null || stats === void 0 ? void 0 : stats.completedTimes) || "-"] })] }) }), _jsx(Row, { children: _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "#" }), _jsx("th", { children: "Activity Profile Id" }), _jsx("th", { children: "User Ids" }), _jsx("th", { children: "Completion" }), _jsx("th", { children: "Time Zone Offset" }), _jsx("th", { children: "Channel Id" }), _jsx("th", { children: "Message Id" })] }) }), (_b = stats === null || stats === void 0 ? void 0 : stats.records) === null || _b === void 0 ? void 0 : _b.map(function (record, index) { return (_jsxs("tr", { children: [_jsx("td", { children: index + 1 }), _jsx("td", { children: record.activityProfileId }), _jsx("td", { children: record.userIds.join(", ") }), _jsx("td", { children: record.completed ? "Completed" : "Not completed" }), _jsx("td", { children: record.timezone }), _jsx("td", { children: record.channelId }), _jsx("td", { children: record.messageId })] }, index)); })] }) })] }));
};
