var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { MUTATION_SEND_PUSH_NOTIFICATION_AS_ADMIN } from "@blueheart/api-spec/src/adminQueries";
import { GQLAdminPushMethod, GQLAdminPushType } from "@blueheart/api-spec/src/generated/graphql";
import { Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { extractStrings } from "../utils/extractStrings";
var Control = Form.Control, Label = Form.Label, Group = Form.Group, Check = Form.Check, Select = Form.Select;
export var Push = function () {
    var _a = __read(useMutation(MUTATION_SEND_PUSH_NOTIFICATION_AS_ADMIN, {}), 2), doMutation = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var _c = __read(useState(undefined), 2), message = _c[0], setMessage = _c[1];
    return (_jsxs(Container, { children: [_jsx(Formik, __assign({ onSubmit: function (values, _a) {
                    var setValues = _a.setValues;
                    setMessage(undefined);
                    var push = {
                        title: values.title,
                        body: values.body,
                        emailsOrIdsOrAnalyticsIds: extractStrings(values.ids),
                        pushType: values.pushType,
                        pushMethod: values.pushMethod,
                        sendInBackgroundAndNotify: values.sendInBackgroundAndNotify
                    };
                    doMutation({
                        variables: {
                            push: push
                        }
                    })
                        .then(function (_a) {
                        var data = _a.data;
                        if (!data || !data.sendPushNotificationsAsAdmin) {
                            throw new Error("No data");
                        }
                        var _b = data.sendPushNotificationsAsAdmin, userCount = _b.userCount, tokenCount = _b.tokenCount, successCount = _b.successCount, failureCount = _b.failureCount;
                        setValues(__assign(__assign({}, values), { sure: false }));
                        setMessage({
                            text: "Batch successfully sent! ".concat(userCount, " users with ").concat(tokenCount, " tokens. ").concat(successCount, " deliveries succeeded and ").concat(failureCount, " deliveries failed."),
                            color: "green"
                        });
                    })
                        .catch(function (err) {
                        setMessage({
                            text: "Error ".concat(err.message),
                            color: "red"
                        });
                    });
                }, initialValues: {
                    title: "",
                    body: "",
                    ids: "",
                    pushType: GQLAdminPushType.DEFAULT,
                    pushMethod: GQLAdminPushMethod.FCM,
                    sure: false,
                    sendInBackgroundAndNotify: false
                } }, { children: function (_a) {
                    var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, values = _a.values, isValid = _a.isValid;
                    return (_jsx(Form, __assign({ onSubmit: handleSubmit }, { children: _jsxs(Group, { children: [_jsx("h2", { children: "Enter Ids or Emails (one per line), the message contents, then press the button" }), message && (_jsx("div", __assign({ style: { color: message.color } }, { children: message.text }))), _jsxs("div", { children: [_jsx(Label, { children: "Title" }), _jsx(Control, { type: "text", name: "title", value: values.title, onChange: handleChange }), _jsx(Label, { children: "Body" }), _jsx(Control, { type: "text", name: "body", value: values.body, onChange: handleChange }), _jsx(Label, __assign({ htmlFor: "ids" }, { children: "Ids and emails" })), _jsx(Control, { as: "textarea", name: "ids", value: values.ids, onChange: handleChange }), _jsxs(Form.Group, { children: [_jsxs(Row, { children: [_jsx(Col, __assign({ xs: 4 }, { children: _jsx(Label, { children: "Push type" }) })), _jsx(Col, { children: _jsx(Select, __assign({ name: "pushType", onChange: handleChange, value: values.pushType }, { children: Object.entries(GQLAdminPushType).map(function (_a) {
                                                                    var _b = __read(_a, 2), name = _b[0], value = _b[1];
                                                                    return (_jsx("option", { id: "pushType-".concat(value), label: name, value: value }, value));
                                                                }) })) })] }), _jsxs(Row, { children: [_jsx(Col, __assign({ xs: 4 }, { children: _jsx(Label, { children: "Push method" }) })), _jsx(Col, { children: _jsx(Select, __assign({ name: "pushMethod", onChange: handleChange, value: values.pushMethod }, { children: Object.entries(GQLAdminPushMethod).map(function (_a) {
                                                                    var _b = __read(_a, 2), name = _b[0], value = _b[1];
                                                                    return (_jsx("option", { id: "pushMethod-".concat(value), label: name, value: value }, value));
                                                                }) })) })] }), _jsxs(Row, { children: [_jsx(Col, __assign({ xs: 4 }, { children: _jsx(Label, { children: "Extra" }) })), _jsx(Col, { children: _jsx(Check, { name: "sendInBackgroundAndNotify", label: "Send in background and notify? (if unsure what this is, leave unchecked)", onChange: handleChange }) })] })] }), _jsx(Check, { required: true, name: "sure", label: "I'm SURE", onChange: handleChange, isInvalid: !values.sure })] }), _jsx(Button, __assign({ type: "submit", disabled: !isValid || loading }, { children: "Send Push Notification" }))] }) })));
                } })), error && (_jsx("div", { children: error.graphQLErrors && error.graphQLErrors.length > 0 ? (error.graphQLErrors.map(function (err) {
                    return _jsx("div", { children: err.message });
                })) : (_jsx("div", { children: error.message })) }))] }));
};
