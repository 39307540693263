var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button, ButtonGroup, Container, Row } from "react-bootstrap";
export var ConfirmOperation = function (_a) {
    var message = _a.message, onConfirm = _a.onConfirm, closeModal = _a.closeModal;
    return (_jsxs(Container, { children: [_jsx(Row, { children: message }), _jsx(Row, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, __assign({ type: "button", onClick: onConfirm }, { children: "Confirm" })), _jsx(Button, __assign({ type: "button", variant: "secondary", onClick: closeModal }, { children: "Cancel" }))] }) })] }));
};
