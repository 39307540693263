var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { MUTATION_GRANT_ACCESS } from "@blueheart/api-spec/src/adminQueries";
import { GQLGrantAccessDuration } from "@blueheart/api-spec/src/generated/graphql";
import { Alert, Form } from "react-bootstrap";
import { Formik } from "formik";
import { extractStrings } from "../utils/extractStrings";
var Control = Form.Control, Label = Form.Label, Group = Form.Group, Check = Form.Check;
export var GrantAccess = function () {
    var _a = __read(useMutation(MUTATION_GRANT_ACCESS, {}), 2), doMutation = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var _c = __read(useState(undefined), 2), message = _c[0], setMessage = _c[1];
    return (_jsxs(Container, { children: [_jsxs(Alert, __assign({ variant: "warning" }, { children: ["This does not cancel any auto-renewing subscriptions the user has. They will need to cancel these themselves. If necessary, send these links to guide the user how to cancel their subscription:", _jsxs("ul", { children: [_jsx("li", { children: "iOS: https://support.apple.com/en-gb/HT202039" }), _jsx("li", { children: "Android: https://support.google.com/googleplay/answer/7018481?hl=en-GB&co=GENIE.Platform%3DAndroid" })] })] })), _jsx(Formik, __assign({ onSubmit: function (values, _a) {
                    var resetForm = _a.resetForm;
                    setMessage(undefined);
                    doMutation({
                        variables: {
                            emails: extractStrings(values.emails),
                            duration: values.duration
                        }
                    })
                        .then(function (_a) {
                        var data = _a.data;
                        console.log("Success");
                        if (!data || !data.grantAccess) {
                            throw new Error("No data");
                        }
                        var _b = data.grantAccess, successes = _b.successes, failures = _b.failures;
                        resetForm();
                        setMessage({
                            text: "Batch successfully sent! Successfully granted: ".concat(successes.length > 0 ? successes.join("") : "none", " & failed to grant: ").concat(failures.length > 0 ? successes.join("") : "none"),
                            color: "green"
                        });
                    })
                        .catch(function (err) {
                        setMessage({
                            text: "Error ".concat(err.message),
                            color: "red"
                        });
                    });
                }, initialValues: {
                    emails: "",
                    duration: GQLGrantAccessDuration.Lifetime,
                    sure: false
                } }, { children: function (_a) {
                    var handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, values = _a.values, isValid = _a.isValid;
                    return (_jsx(Form, __assign({ onSubmit: handleSubmit }, { children: _jsxs(Group, { children: [_jsx("h2", { children: "Enter Emails (one per line), then press the button" }), message && (_jsx("div", __assign({ style: { color: message.color } }, { children: message.text }))), _jsxs("div", { children: [_jsx(Label, __assign({ htmlFor: "emails" }, { children: "Emails" })), _jsx(Control, { as: "textarea", name: "emails", value: values.emails, onChange: handleChange }), _jsxs(Group, { children: [_jsx(Label, { children: "Duration" }), _jsx(Control, __assign({ required: true, as: "select", name: "duration", value: values.duration, onChange: handleChange }, { children: Object.values(GQLGrantAccessDuration).map(function (duration) { return (_jsx("option", __assign({ value: duration }, { children: duration }))); }) }))] }), _jsx(Check, { required: true, name: "sure", label: "I'm SURE", onChange: handleChange, isInvalid: !values.sure })] }), _jsx(Button, __assign({ type: "submit", disabled: !isValid || loading }, { children: "Grant Access" }))] }) })));
                } })), error && (_jsxs("div", { children: [_jsx("div", { children: error.message }), error.graphQLErrors.map(function (err) {
                        return _jsx("div", { children: err.message });
                    })] }))] }));
};
