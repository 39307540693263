var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { swapNullForUndefined } from "@blueheart/utils/src/withoutNull";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Modal, Table, ToggleButton } from "react-bootstrap";
import { isPlainDateLessThanOrEqual } from "@blueheart/utils/src/date";
import { useMutation } from "@apollo/client";
import { MUTATION_DELETE_EXPERIMENT, QUERY_GET_ACTIVE_EXPERIMENTS, QUERY_GET_EXPERIMENTS } from "@blueheart/api-spec/src/adminQueries";
import styled from "styled-components";
import { filterNoEndDate, filterNonOnboarding, filterNoStartDate, filterOnboarding, formatDateString } from "../../utils/experimentsTable";
import { EditExperiment } from "./EditExperiment";
var GreyedTd = styled.td(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: grey;\n"], ["\n  color: grey;\n"])));
var ErrorMsg = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: red;\n"], ["\n  color: red;\n"])));
var ExperimentsFilterType;
(function (ExperimentsFilterType) {
    ExperimentsFilterType["All"] = "all";
    ExperimentsFilterType["Onboarding"] = "onboarding";
    ExperimentsFilterType["Other"] = "other";
    ExperimentsFilterType["WithoutStart"] = "withoutStart";
    ExperimentsFilterType["WithoutEnd"] = "withoutEnd";
})(ExperimentsFilterType || (ExperimentsFilterType = {}));
export var ExperimentsTable = function (_a) {
    var data = _a.data, loading = _a.loading, showEditModal = _a.showEditModal, setShowEditModal = _a.setShowEditModal, setConfirmModalContents = _a.setConfirmModalContents;
    var _b = __read(useMutation(MUTATION_DELETE_EXPERIMENT, {
        refetchQueries: [
            { query: QUERY_GET_EXPERIMENTS },
            { query: QUERY_GET_ACTIVE_EXPERIMENTS }
        ]
    }), 2), deleteExperiment = _b[0], _c = _b[1], deleteLoading = _c.loading, error = _c.error;
    var filterOptions = [
        { name: "All", value: ExperimentsFilterType.All },
        { name: "Onboarding", value: ExperimentsFilterType.Onboarding },
        { name: "Non-onboarding", value: ExperimentsFilterType.Other },
        { name: "No start date", value: ExperimentsFilterType.WithoutStart },
        { name: "No end date", value: ExperimentsFilterType.WithoutEnd }
    ];
    var _d = __read(useState(ExperimentsFilterType.All), 2), selectedFilter = _d[0], setSelectedFilter = _d[1];
    var _e = __read(useState(data), 2), experiments = _e[0], setExperiments = _e[1];
    var filterData = useCallback(function (option) {
        var filteredData;
        switch (option) {
            case ExperimentsFilterType.Onboarding:
                filteredData = filterOnboarding(data);
                break;
            case "other":
                filteredData = filterNonOnboarding(data);
                break;
            case "withoutStart":
                filteredData = filterNoStartDate(data);
                break;
            case "withoutEnd":
                filteredData = filterNoEndDate(data);
                break;
            case "all":
            default:
                filteredData = data;
        }
        return filteredData;
    }, [data]);
    useEffect(function () {
        setExperiments(filterData(selectedFilter));
    }, [selectedFilter, filterData]);
    var today = useMemo(function () { return new Date(); }, []);
    return (_jsxs(_Fragment, { children: [_jsx(ButtonGroup, __assign({ className: "table-filter" }, { children: filterOptions.map(function (option, index) { return (_jsx(ToggleButton, __assign({ variant: "outline-primary", type: "radio", name: "radio", value: option.value, checked: selectedFilter === option.value, onChange: function (e) {
                        return setSelectedFilter(e.currentTarget.value);
                    } }, { children: option.name }), index)); }) })), experiments &&
                !loading &&
                !deleteLoading &&
                (experiments.length === 0 ? (_jsx("div", { children: "No data was found." })) : (_jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Name" }), _jsx("th", { children: "Type" }), _jsx("th", { children: "Variants" }), _jsx("th", { children: "Split" }), _jsx("th", { children: "Start date" }), _jsx("th", { children: "End date" }), _jsx("th", { children: "Winning Variant" }), _jsx("th", {})] }) }), _jsx("tbody", { children: experiments.map(function (experiment, i) {
                                var modalEditKey = "edit-".concat(i);
                                var startDate = swapNullForUndefined(experiment.startDate);
                                var endDate = swapNullForUndefined(experiment.endDate);
                                return (_jsxs("tr", { children: [_jsx("td", __assign({ title: experiment.id }, { children: experiment.name })), _jsx("td", { children: experiment.type }), _jsx("td", { children: experiment.numberOfVariants }), experiment.split ? (_jsx("td", { children: experiment.split })) : (_jsx(GreyedTd, { children: "Split equally" })), _jsx("td", { children: formatDateString(experiment.startDate) }), _jsx("td", { children: formatDateString(experiment.endDate) }), _jsx("td", { children: experiment.winningVariant }), _jsxs("td", { children: [_jsx(Modal, __assign({ show: showEditModal === modalEditKey, onHide: function () { return setShowEditModal(null); } }, { children: _jsx(EditExperiment, { experimentId: experiment.id, experimentName: experiment.name, onEdited: function () { return setShowEditModal(null); }, initialStartDate: startDate, initialEndDate: endDate, initialWinningVariant: swapNullForUndefined(experiment.winningVariant) }) })), _jsx(Button, __assign({ variant: "outline-primary", size: "sm", onClick: function () { return setShowEditModal(modalEditKey); } }, { children: "Edit" })), (!experiment.startDate ||
                                                    (experiment.startDate &&
                                                        !isPlainDateLessThanOrEqual(new Date(experiment.startDate), today))) && (_jsx(Button, __assign({ variant: "outline-danger", size: "sm", onClick: function () {
                                                        return setConfirmModalContents({
                                                            message: "Are you sure you want to delete '".concat(experiment.name, "'?"),
                                                            onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                                return __generator(this, function (_a) {
                                                                    switch (_a.label) {
                                                                        case 0: return [4 /*yield*/, deleteExperiment({
                                                                                variables: {
                                                                                    experimentId: experiment.id
                                                                                }
                                                                            })];
                                                                        case 1:
                                                                            _a.sent();
                                                                            setConfirmModalContents(null);
                                                                            return [2 /*return*/];
                                                                    }
                                                                });
                                                            }); },
                                                            closeModal: function () { return setConfirmModalContents(null); }
                                                        });
                                                    } }, { children: "Delete" })))] })] }, i));
                            }) })] }))), error && _jsx(ErrorMsg, { children: error.message || "Unknown error" })] }));
};
var templateObject_1, templateObject_2;
