var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { adminConsoleConfig } from "../config/envs/env";
import { rootReducer, rootSaga } from "./root";
import { selectAuthHeader } from "./auth/selectors";
export var configureStore = function (initialState, options) {
    var middleware = [];
    var logger = require("redux-logger").logger; // eslint-disable-line @typescript-eslint/no-var-requires
    middleware.push(logger);
    var sagaMiddleware = createSagaMiddleware();
    middleware.push(sagaMiddleware);
    var appliedMiddleware = applyMiddleware.apply(void 0, __spreadArray([], __read(middleware), false));
    var composeWithDevTools = require("redux-devtools-extension").composeWithDevTools; // eslint-disable-line @typescript-eslint/no-var-requires
    appliedMiddleware = composeWithDevTools(appliedMiddleware);
    var storeEnhancers = __spreadArray([
        appliedMiddleware
    ], __read(((options && options.extraStoreEnhancers) || [])), false);
    var store = createStore(rootReducer, initialState, compose.apply(void 0, __spreadArray([], __read(storeEnhancers), false)));
    store.runSaga = function (saga, arg) {
        return sagaMiddleware.run(saga, arg);
    };
    var httpLink = new HttpLink({
        uri: "".concat(adminConsoleConfig.blueheartUrls.BLUEHEART_API_URL, "/graphql"),
        fetch: window.fetch
    });
    var authLink = setContext(function (_, _a) {
        var headers = _a.headers;
        if (!headers) {
            headers = {};
        }
        var authHeader = selectAuthHeader(store.getState());
        if (authHeader && !headers.authorization) {
            // only add the auth header here if the caller hasn't already set it
            headers.authorization = authHeader;
        }
        return { headers: headers };
    });
    var onErrorLink = onError(function (_a) {
        var networkError = _a.networkError;
        if (networkError &&
            "statusCode" in networkError &&
            networkError.statusCode) {
            var rateLimitResetHeader = networkError.response.headers.get("X-RateLimit-Reset");
            var retryAtEpochMs = rateLimitResetHeader
                ? parseInt(rateLimitResetHeader, 10) * 1000
                : undefined;
            if (retryAtEpochMs && networkError.statusCode === 429) {
                console.error("Hit rate limit and got 429, allowed to make a new request at ".concat(new Date(retryAtEpochMs).toISOString()));
            }
        }
    });
    var client = new ApolloClient({
        link: from([authLink, onErrorLink, httpLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                errorPolicy: "all"
            },
            mutate: {
                errorPolicy: "all"
            }
        }
    });
    if (!options || !options.disableSagas) {
        store.runSaga(rootSaga, {
            client: client
        });
    }
    return { store: store, client: client };
};
