var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CourseIdEnum } from "@blueheart/content/src/versionedGenerated/contentful/courseIdEnum";
import { MUTATION_ASSOCIATE_STRIPE_CUSTOMER_ID_AS_ADMIN, MUTATION_CHANGE_COURSE_AS_ADMIN, MUTATION_LINK_USERS_AS_ADMIN, MUTATION_UNLINK_USER_AS_ADMIN, QUERY_GET_USER_WITH_PARTNER_INFO_AS_ADMIN, QUERY_SEARCH_FOR_USERS_AS_ADMIN } from "@blueheart/api-spec/src/adminQueries";
import { Alert, Button, Card, Container, Modal, Stack } from "react-bootstrap";
import { isNotNil } from "@blueheart/utils/src/isNotNil";
import Table from "react-bootstrap/Table";
import { sendPasswordResetEmail } from "@firebase/auth";
import ms from "ms";
import { firebaseAuth } from "../../services/firebase";
import { Header } from "../components/Header";
import { Detail } from "../components/Detail";
import { AddEmailButton } from "./AddEmailButton";
import { DangerZone } from "./DangerZone";
import { AddFirebaseAccount } from "./AddFirebaseAccount";
import { RemoveEmailButton } from "./RemoveEmailButton";
var LinkUserLookupModal = function (_a) {
    var id = _a.id, shouldShow = _a.shouldShow, onHide = _a.onHide;
    var _b = __read(useLazyQuery(QUERY_SEARCH_FOR_USERS_AS_ADMIN, {
        fetchPolicy: "network-only"
    }), 2), doFetch = _b[0], _c = _b[1], fetchLoading = _c.loading, fetchError = _c.error, fetchData = _c.data;
    var _d = __read(useMutation(MUTATION_LINK_USERS_AS_ADMIN, {
        refetchQueries: ["getUserAsAdmin", "getUserWithPartnerInfoAsAdmin"]
    }), 2), doLink = _d[0], _e = _d[1], linkLoading = _e.loading, linkError = _e.error;
    var _f = __read(useState(""), 2), searchTerm = _f[0], setSearchTerm = _f[1];
    return (_jsxs(Modal, __assign({ show: shouldShow, onHide: onHide, size: "xl" }, { children: [_jsx(Modal.Header, __assign({ closeButton: true }, { children: _jsx(Modal.Title, { children: "Link?" }) })), _jsx(Modal.Body, { children: fetchLoading || linkLoading ? ("loading") : (_jsxs(_Fragment, { children: [_jsx("div", { children: "Search for user to link with" }), _jsx("input", { type: "text", required: true, value: searchTerm, onChange: function (e) { return setSearchTerm(e.currentTarget.value); } }), _jsx(Button, __assign({ onClick: function () { return doFetch({ variables: { ids: [searchTerm] } }); } }, { children: "Search" })), (fetchData === null || fetchData === void 0 ? void 0 : fetchData.searchForUsersAsAdmin) && (_jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Id" }), _jsx("th", { children: "Analytics Id" }), _jsx("th", { children: "Email" }), _jsx("th", { children: "Action" })] }) }), _jsx("tbody", { children: fetchData === null || fetchData === void 0 ? void 0 : fetchData.searchForUsersAsAdmin.rows.map(function (row) { return row.user; }).filter(isNotNil).map(function (user, index) { return (_jsxs("tr", { children: [_jsx("td", { children: user.id }), _jsx("td", { children: user.analyticsId }), _jsx("td", { children: user.email }), _jsx("td", { children: user.id !== id ? (_jsx(Button, __assign({ onClick: function () {
                                                        return doLink({
                                                            variables: { id: id, partnerId: user.id }
                                                        })
                                                            .then(onHide)
                                                            .catch(console.error);
                                                    } }, { children: "Link with this user" }))) : (_jsx(Alert, __assign({ variant: "danger" }, { children: "Cannot link with self" }))) })] }, index)); }) })] })), fetchError && _jsx("div", { children: "Error fetching: ".concat(fetchError) }), linkError && _jsx("div", { children: "Error linking: ".concat(linkError) })] })) })] })));
};
var UnlinkModal = function (_a) {
    var id = _a.id, shouldShow = _a.shouldShow, onHide = _a.onHide;
    var _b = __read(useMutation(MUTATION_UNLINK_USER_AS_ADMIN, {
        refetchQueries: ["getUserAsAdmin", "getUserWithPartnerInfoAsAdmin"]
    }), 2), doUnlink = _b[0], _c = _b[1], unlinkLoading = _c.loading, unLinkingError = _c.error;
    return (_jsxs(Modal, __assign({ show: shouldShow, onHide: onHide }, { children: [_jsx(Modal.Header, __assign({ closeButton: true }, { children: _jsx(Modal.Title, { children: "Unlink?" }) })), _jsxs(Modal.Body, { children: ["The user will be unlinked from their partner, and any invites or acceptances they have created will be deleted", unLinkingError && "Error: ".concat(unLinkingError)] }), unlinkLoading ? ("loading") : (_jsx(Button, __assign({ variant: "danger", onClick: function () {
                    return doUnlink({ variables: { id: id } }).then(onHide).catch(console.error);
                } }, { children: "Unlink" })))] })));
};
var PartnerLinkingSection = function (_a) {
    var partnerLinkInfo = _a.partnerLinkInfo, id = _a.id;
    var _b = __read(useState(false), 2), shouldShowUnlinkModal = _b[0], setShouldShowUnlinkModal = _b[1];
    var _c = __read(useState(false), 2), shouldShowLinkWithUserModal = _c[0], setShouldShowLinkModal = _c[1];
    if (!partnerLinkInfo) {
        return _jsx("span", { children: "Could not get partnerLinkInfo" });
    }
    var partner = partnerLinkInfo.partner, partiallyLinkedPartners = partnerLinkInfo.partiallyLinkedPartners;
    return (_jsxs("div", { children: [_jsx(LinkUserLookupModal, { id: id, partnerLinkInfo: partnerLinkInfo, shouldShow: shouldShowLinkWithUserModal, onHide: function () { return setShouldShowLinkModal(false); } }), _jsx(UnlinkModal, { id: id, shouldShow: shouldShowUnlinkModal, onHide: function () { return setShouldShowUnlinkModal(false); } }), _jsx(Button, __assign({ variant: "danger", onClick: function () { return setShouldShowUnlinkModal(true); } }, { children: "Unlink all" })), partner ? (_jsx(_Fragment, { children: _jsxs("div", { children: ["Linked to a user", _jsxs("dl", { children: [_jsx("dt", { children: "Partner user id" }), _jsx("dd", { children: _jsx(Link, __assign({ to: "/user/".concat(partner.id) }, { children: partner.id })) }), _jsx("dt", { children: "Partner email" }), _jsx("dd", { children: partner.email })] })] }) })) : (_jsxs("div", { children: [_jsx("div", { children: "Not linked with anyone" }), _jsx(Button, __assign({ variant: "danger", onClick: function () { return setShouldShowLinkModal(true); } }, { children: "Link" }))] })), partiallyLinkedPartners.length > 1 ? (_jsxs("div", { children: ["Has partial linkings:", partiallyLinkedPartners.map(function (partialLinkedPartner) { return (_jsxs("div", { children: [_jsx(Link, __assign({ to: "/user/".concat(partialLinkedPartner.id) }, { children: partialLinkedPartner.id })), " ", partialLinkedPartner.email] })); })] })) : undefined] }));
};
var ChangeCourseModal = function (_a) {
    var id = _a.id, initialCourseId = _a.initialCourseId, shouldShow = _a.shouldShow, onHide = _a.onHide, partner = _a.partner;
    var _b = __read(useMutation(MUTATION_CHANGE_COURSE_AS_ADMIN, {
        refetchQueries: ["getUserAsAdmin", "getUserWithPartnerInfoAsAdmin"]
    }), 2), doChangeCourse = _b[0], _c = _b[1], changeCourseLoading = _c.loading, changeCourseError = _c.error;
    var _d = __read(useState(initialCourseId || ""), 2), courseId = _d[0], setCourseId = _d[1];
    var courses = Object.values(CourseIdEnum);
    return (_jsxs(Modal, __assign({ show: shouldShow, onHide: onHide }, { children: [_jsx(Modal.Header, __assign({ closeButton: true }, { children: _jsx(Modal.Title, { children: "Change course" }) })), partner && (_jsxs(Modal.Body, { children: ["This will also change the linked partner's course. The partner has id", " ", _jsx("pre", { children: partner.id }), partner.email && (_jsxs(_Fragment, { children: [" ", "and email ", _jsx("pre", { children: partner.email })] }))] })), _jsxs("select", __assign({ value: courseId, onChange: function (e) { return setCourseId(e.currentTarget.value); } }, { children: [_jsx("option", { value: "", label: "Choose a course" }), courses.map(function (c) { return (_jsx("option", { value: c, label: c }, c)); })] })), changeCourseLoading ? ("loading") : (_jsx(Button, __assign({ disabled: !courseId, onClick: function () {
                    if (!courseId) {
                        throw new Error("Developer error, should have courseId");
                    }
                    doChangeCourse({ variables: { id: id, courseId: courseId } })
                        .then(onHide)
                        .catch(console.error);
                } }, { children: "Change" }))), _jsx("span", { children: "".concat(changeCourseError || "") })] })));
};
export var UserInfo = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var id = useParams().id;
    var _k = useQuery(QUERY_GET_USER_WITH_PARTNER_INFO_AS_ADMIN, {
        variables: {
            id: id
        },
        errorPolicy: "ignore"
    }), loading = _k.loading, error = _k.error, data = _k.data, refetch = _k.refetch;
    var _l = __read(useState(false), 2), shouldShowEditCourseModal = _l[0], setShouldShowEditCourseModal = _l[1];
    var user = (data === null || data === void 0 ? void 0 : data.getUserAsAdmin.__typename) === "GQLForeignUserAsAdmin"
        ? data.getUserAsAdmin
        : undefined;
    var creationTimeAgoString = useMemo(function () {
        if (!(user === null || user === void 0 ? void 0 : user.created)) {
            return undefined;
        }
        var now = Date.now();
        var creationTime = new Date(user.created).getTime();
        var diff = now - creationTime;
        return ms(diff, { long: true });
    }, [user === null || user === void 0 ? void 0 : user.created]);
    if (loading) {
        return _jsx("div", { children: "loading" });
    }
    if (error) {
        return (_jsxs("div", { children: [_jsxs("div", { children: ["Error loading: ", "".concat(error)] }), _jsx(Button, __assign({ onClick: function () { return refetch(); } }, { children: "Refetch" }))] }));
    }
    if (!data || !user) {
        return (_jsxs("div", { children: [_jsx("div", { children: "Unexpectedly, did not get exactly one user" }), _jsx(Button, __assign({ onClick: function () { return refetch(); } }, { children: "Refetch" }))] }));
    }
    return (_jsxs(Container, __assign({ style: { marginTop: 16, marginBottom: 16 } }, { children: [_jsx(ChangeCourseModal, { shouldShow: shouldShowEditCourseModal, id: id, initialCourseId: user.courseId || undefined, partner: ((_a = user.partnerLinkInfo) === null || _a === void 0 ? void 0 : _a.partner) || undefined, onHide: function () { return setShouldShowEditCourseModal(false); } }), _jsxs(Stack, __assign({ gap: 3 }, { children: [_jsxs(Header, __assign({ title: "User Details" }, { children: ["Details | ", _jsx(Link, __assign({ to: "/user/".concat(user.id, "/progress") }, { children: "Progress" }))] })), _jsxs(Card, { children: [_jsx(Card.Header, { children: "Details" }), _jsx(Card.Body, { children: _jsxs("dl", { children: [_jsx(Detail, __assign({ title: "User Id" }, { children: user.id })), _jsxs(Detail, __assign({ title: "Analytics Id" }, { children: [user.analyticsId, " ", _jsx("a", __assign({ href: "https://analytics.amplitude.com/blueheartio/activity", target: "_blank", rel: "noreferrer" }, { children: "(amplitude)" }))] })), _jsx(Detail, __assign({ title: "Email" }, { children: user.email ? (_jsxs(_Fragment, { children: [user.email, " ", _jsx(RemoveEmailButton, { user: user })] })) : (_jsxs(_Fragment, { children: ["n/a ", _jsx(AddEmailButton, { user: user })] })) })), _jsxs(Detail, __assign({ title: "Course Id" }, { children: [user.courseId, " ", _jsx(Button, __assign({ onClick: function () { return setShouldShowEditCourseModal(true); } }, { children: "edit" }))] })), _jsxs(Detail, __assign({ title: "Account created dated" }, { children: [_jsx("code", { children: new Date(user.created).toISOString() }), " (", creationTimeAgoString, " ago)"] })), _jsx(Detail, __assign({ title: "Device Identifiers" }, { children: _jsx("dl", { children: (_b = user.deviceIdentifiers) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                                                    var identifier = _a.identifier, kind = _a.kind;
                                                    return (_jsxs(_Fragment, { children: [_jsx("dt", { children: kind }), _jsx("dd", { children: identifier })] }));
                                                }) }) }))] }) })] }), _jsxs(Card, { children: [_jsx(Card.Header, { children: "Info" }), _jsx(Card.Body, { children: _jsxs("dl", { children: [_jsx(Detail, __assign({ title: "App version" }, { children: user.lastSeenAppVersion })), _jsx(Detail, __assign({ title: "Timezone" }, { children: user.timezone })), _jsx(Detail, __assign({ title: "Name" }, { children: user.name })), _jsx(Detail, __assign({ title: "Avatar" }, { children: user.avatar }))] }) })] }), _jsxs(Card, { children: [_jsx(Card.Header, { children: "Chat status" }), _jsx(Card.Body, { children: _jsxs("dl", { children: [_jsx(Detail, __assign({ title: "Prefered timezone" }, { children: (_c = user.chatInfo) === null || _c === void 0 ? void 0 : _c.preferredTimezone })), _jsx(Detail, __assign({ title: "Chat Id" }, { children: (_d = user.chatInfo) === null || _d === void 0 ? void 0 : _d.chatId })), _jsx(Detail, __assign({ title: "Chat Channel" }, { children: (_e = user.chatInfo) === null || _e === void 0 ? void 0 : _e.chatChannelId })), _jsx(Detail, __assign({ title: "Chat Token Exists" }, { children: _jsx("span", __assign({ style: ((_f = user.chatInfo) === null || _f === void 0 ? void 0 : _f.hasToken) === false &&
                                                    ((_g = user.chatInfo) === null || _g === void 0 ? void 0 : _g.chatChannelId)
                                                    ? {
                                                        color: "red"
                                                    }
                                                    : {} }, { children: JSON.stringify((_h = user.chatInfo) === null || _h === void 0 ? void 0 : _h.hasToken) })) })), _jsx(Detail, __assign({ title: "Chat Token Cration Date" }, { children: (_j = user.chatInfo) === null || _j === void 0 ? void 0 : _j.tokenCreationDate }))] }) })] }), _jsxs(Card, { children: [_jsx(Card.Header, { children: "Partner linking" }), _jsx(Card.Body, { children: _jsx(PartnerLinkingSection, { partnerLinkInfo: user.partnerLinkInfo, id: id }) })] }), _jsxs(Card, { children: [_jsx(Card.Header, { children: "Authentication" }), _jsx(Card.Body, { children: _jsxs("dl", { children: [user.firebaseUid ? (_jsx(Detail, __assign({ title: "Firebase Uid" }, { children: user.firebaseUid }))) : user.email ? (_jsx(AddFirebaseAccount, { user: user })) : undefined, user.auth0id ? (_jsx(Detail, __assign({ title: "Auth0 Id" }, { children: user.auth0id }))) : null, user.firebaseLoginProviders ? (_jsxs(_Fragment, { children: [_jsx("dt", { children: "Firebase auth providers" }), _jsx("dd", { children: _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Provider Id" }), _jsx("th", { children: "Uid" }), _jsx("th", { children: "Display Name" }), _jsx("th", { children: "Email" }), _jsx("th", { children: "Action" })] }) }), _jsx("tbody", { children: user.firebaseLoginProviders
                                                                    .filter(isNotNil)
                                                                    .map(function (p, n) { return (_jsxs("tr", { children: [_jsx("td", { children: p.providerId }), _jsx("td", { children: p.uid }), _jsx("td", { children: p.displayName }), _jsx("td", { children: p.email }), _jsx("td", { children: p.providerId === "password" && p.email ? (_jsx(ResetPasswordButton, { email: p.email })) : null })] }, n)); }) })] }) })] })) : null] }) })] }), _jsxs(Card, { children: [_jsx(Card.Header, { children: "RevenueCat" }), _jsxs(Card.Body, { children: [_jsx("a", __assign({ href: "https://app.revenuecat.com/customers/14f1406c/".concat(user.id), target: "_blank", rel: "noreferrer" }, { children: "Go to RevenueCat" })), user.purchaserInfo ? (_jsxs("dl", { children: [_jsx("dt", { children: "Subscriptions" }), _jsx("dd", { children: user.purchaserInfo.subscriptions.length > 0 ? (_jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Store" }), _jsx("th", { children: "Period Type" }), _jsx("th", { children: "Purchase Date" }), _jsx("th", { children: "Expiration Date" })] }) }), _jsx("tbody", { children: user.purchaserInfo.subscriptions.map(function (s, n) { return (_jsxs("tr", { children: [_jsx("td", { children: s.store }), _jsx("td", { children: s.period_type }), _jsx("td", { children: s.purchase_date }), _jsx("td", { children: s.expires_date })] }, n)); }) })] })) : ("No subscriptions") })] })) : null] })] }), _jsx(StripeCard, { user: user }), _jsx(DangerZone, { user: user })] }))] })));
};
var StripeCard = function (_a) {
    var user = _a.user;
    var _b = __read(useMutation(MUTATION_ASSOCIATE_STRIPE_CUSTOMER_ID_AS_ADMIN), 2), attachStripeCustomer = _b[0], _c = _b[1], loading = _c.loading, error = _c.error;
    var _d = __read(useState(false), 2), isModalOpen = _d[0], setIsModalOpen = _d[1];
    var _e = __read(useState(""), 2), newStripeCustomerId = _e[0], setNewStripeCustomerId = _e[1];
    var stripeInfo = user.stripeInfo;
    return (_jsxs(Card, { children: [_jsx(Card.Header, { children: "Stripe" }), _jsxs(Card.Body, { children: [stripeInfo ? (_jsxs(_Fragment, { children: [_jsx("a", __assign({ href: "https://dashboard.stripe.com/".concat("livemode" in stripeInfo && !stripeInfo.livemode ? "test/" : "", "customers/").concat(user.stripeCustomerId), target: "_blank", rel: "noreferrer" }, { children: "Go to Stripe" })), stripeInfo.__typename === "GQLStripeActiveUserAsAdmin" ? (_jsxs("dl", { children: [_jsx("dt", { children: "Email" }), _jsx("dd", { children: stripeInfo.email }), _jsx("dt", { children: "Stripe Customer Id" }), _jsx("dd", { children: stripeInfo.stripeCustomerId }), _jsx("dt", { children: "Currency" }), _jsx("dd", { children: stripeInfo.currency }), _jsx("dt", { children: "LiveMode" }), _jsx("dd", { children: stripeInfo.livemode ? "true" : "false" }), _jsx("dt", { children: "Blueheart User Id" }), _jsx("dd", { children: stripeInfo.blueheartUserId })] })) : (_jsx("p", { children: "Stripe User Deleted" }))] })) : (_jsx("p", { children: "No stripe info" })), _jsx(Button, __assign({ variant: "danger", onClick: function () { return setIsModalOpen(true); } }, { children: "Override Stripe Customer ID" })), _jsxs(Modal, __assign({ show: isModalOpen, onHide: function () { return setIsModalOpen(false); } }, { children: [_jsx(Modal.Header, __assign({ closeButton: true }, { children: _jsx(Modal.Title, { children: "Override Stripe Customer ID" }) })), _jsxs(Modal.Body, { children: [_jsx("input", { type: "text", onChange: function (e) { return setNewStripeCustomerId(e.target.value); }, value: newStripeCustomerId }), _jsx(Button, __assign({ disabled: loading || !newStripeCustomerId, onClick: function () {
                                            return attachStripeCustomer({
                                                variables: {
                                                    userId: user.id,
                                                    stripeCustomerId: newStripeCustomerId
                                                }
                                            });
                                        } }, { children: "Set Stripe Customer Id" })), _jsx("span", { children: error ? error.message : "" })] })] }))] })] }));
};
var ResetPasswordButton = function (_a) {
    var email = _a.email;
    var _b = __read(useState(undefined), 2), error = _b[0], setError = _b[1];
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var _d = __read(useState(undefined), 2), message = _d[0], setMessage = _d[1];
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ disabled: isLoading, onClick: function () {
                    setIsLoading(true);
                    sendPasswordResetEmail(firebaseAuth, email)
                        .then(function () {
                        setIsLoading(false);
                        setError(undefined);
                        setMessage("Password reset email sent!");
                    })
                        .catch(function (e) {
                        setIsLoading(false);
                        setError(e);
                    });
                } }, { children: "Send reset email" })), error && _jsx("span", { children: error.message }), message && _jsx("span", { children: message })] }));
};
