export var getActionCreatorsArray = function (actionCreators) {
    if (typeof actionCreators === "undefined") {
        return [];
    }
    if (typeof actionCreators === "function") {
        return [actionCreators];
    }
    else if (Array.isArray(actionCreators)) {
        return actionCreators.flatMap(function (child) {
            return getActionCreatorsArray(child);
        });
    }
    else if (typeof actionCreators === "object") {
        return Object.values(actionCreators).flatMap(function (child) {
            return getActionCreatorsArray(child);
        });
    }
    else {
        throw new Error("Invalid actions object ".concat(actionCreators));
    }
};
