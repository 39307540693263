import { createSelector } from "reselect";
import { LoadingState } from "@blueheart/utils/src/loadingState";
var root = function (state) { return state.auth; };
export var selectLoginState = createSelector(root, function (state) { return state.login; });
export var selectAuthToken = createSelector(selectLoginState, function (loginState) {
    if (loginState.state === LoadingState.Success) {
        return loginState.data.token;
    }
});
export var selectAuthHeader = createSelector(selectAuthToken, function (authToken) {
    if (authToken) {
        return "Bearer ".concat(authToken);
    }
});
