var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { devEnv } from "./dev/dev";
import { localEnv } from "./local/local";
import { prodEnv } from "./prod/prod";
import { stagingEnv } from "./staging/staging";
// eslint-disable-next-line no-process-env
var _a = process.env, REACT_APP_DASHBOARD_ENV = _a.REACT_APP_DASHBOARD_ENV, NODE_ENV = _a.NODE_ENV;
var config;
export var appEnv = REACT_APP_DASHBOARD_ENV || "prod";
console.log("REACT_APP_DASHBOARD_ENV:", REACT_APP_DASHBOARD_ENV);
console.log("NODE_ENV:", NODE_ENV);
console.log("appEnv:", appEnv);
switch (appEnv) {
    case "dev":
        config = devEnv;
        break;
    case "local":
        config = localEnv;
        break;
    case "prod":
        config = prodEnv;
        break;
    case "staging":
        config = stagingEnv;
        break;
    default:
        throw new Error("Unknown app env: " + appEnv);
}
export var adminConsoleConfig = __assign({ REACT_APP_DASHBOARD_ENV: REACT_APP_DASHBOARD_ENV, NODE_ENV: NODE_ENV }, config);
