import { isAfter, isBefore, startOfDay } from "date-fns";
export var newDate = function () { return new Date(); };
export var serializeDate = function (dateIn) {
    var date = new Date(dateIn);
    date.setMilliseconds(0);
    return date.toISOString();
};
/**
 * Compares two dates ignoring the time, and returns true if the first date
 * is earlier than the second date.
 */
export var isPlainDateLessThan = function (first, second) {
    var firstDate = startOfDay(first);
    var secondDate = startOfDay(second);
    return isBefore(firstDate, secondDate);
};
/**
 * Compares two dates ignoring the time, and returns true if the first date
 * is earlier or the same day as the second date.
 */
export var isPlainDateLessThanOrEqual = function (first, second) {
    var firstDate = startOfDay(first);
    var secondDate = startOfDay(second);
    return !isAfter(firstDate, secondDate);
};
